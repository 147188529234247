import criminalLaw from './criminal_law';
import employmentLaw from './employment_law';
import familyLaw from './family_law';
import estatesAndProbates from './estates_and_probates';
import realEstate from './real_estate';
import { LibraryCategory } from '../../../../types/library';

const libraryAssets: LibraryCategory[] = [
  criminalLaw,
  employmentLaw,
  estatesAndProbates,
  familyLaw,
  realEstate,
];

export default libraryAssets;

export const canadaDescription =
  // eslint-disable-next-line @stylistic/js/max-len
  'We have curated some common example legal cases created by our users from common areas of law. Browse the categories for your area of interest.';
