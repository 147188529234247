/* eslint-disable @stylistic/js/max-len */
import { LibraryCategory } from '../../../../types/library';
import cases from './cases.json';
import Icon from '../images/criminal-law.svg?react';
import bubbleImage from '../images/criminal-law-bubble.png';
import criminalImage from '../images/criminal.jpg';
import criminalImageFull from '../images/criminal-full.jpg';

const TITLE = 'Criminal law';

const criminalLaw: LibraryCategory = {
  name: TITLE,
  title: 'Introduction to Criminal law in Australia',
  image: criminalImage,
  previewImage: criminalImageFull,
  shortDescription:
    'Maintain order, protect individuals and property, and uphold justice.',
  description:
    'Criminal law encompasses laws related to offences against the state, individuals, and public safety. It includes a wide range of offences, from serious crimes like murder and assault to minor infractions such as traffic violations. <br/><br/>Criminal law aims to maintain public order, protect individuals and property, and uphold justice by enforcing laws and punishing offenders. It is governed by both state and federal legislation, with penalties and procedures varying across jurisdictions.',
  cases: cases.filter((c) => c.category.toLocaleLowerCase() === TITLE.toLocaleLowerCase()),
  Icon,
  bubbleImage,
  bodyText: [
    {
      header: 'Criminal code act 1995',
      body: 'The primary legislation governing criminal offenses in Australia, detailing crimes and outlining penalties, procedures, and jurisdictional authority for federal offenses.'
    },
    {
      header: 'Types of criminal offenses',
      body: 'Criminal law divides offenses into categories, including summary (less severe) and indictable offenses (more severe), covering areas like theft, assault, drug trafficking, and fraud.'
    },
    {
      header: 'The right to a fair trial',
      body: 'Individuals accused of a crime are entitled to a fair and public hearing by an impartial tribunal, with legal representation and protections against self-incrimination.'
    },
    {
      header: 'Sentencing and penalties',
      body: 'Criminal law establishes sentencing guidelines, which consider factors like the severity of the offense, prior criminal history, and potential for rehabilitation to determine appropriate penalties.'
    },
    {
      header: 'Victims’ rights and support',
      body: 'Victims of crime have rights to information, protection, and support services, including assistance with court proceedings and access to compensation and counseling services.'
    }
  ],
};

export default criminalLaw;
