import React, { FC, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import libraryAssets from '../assets';
import { convertToKebabCase } from '../../../utils/case-conversion';
import { Header } from '../../../components/header';
import { LibraryHero } from '../hero';
import { LibraryTextWrapper } from './text-wrapper';
import { NeedHelpCard } from '../../../components/need-help';
import { LibraryCarousel } from '../carousel';
import { Footer } from '../../../components/footer';
import style from './style.module.less';
import {
  getLibraryPathWithCountry,
} from '../../../utils/get-library-path-with-country';

export const LibraryCase: FC = () => {
  const { category, caseName } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const libraryCategory = libraryAssets.find(
    (c) => convertToKebabCase(c?.name) === category
  );
  const libraryCase = libraryCategory?.cases?.find(
    (c) => convertToKebabCase(c?.name) === caseName
  );

  useEffect(() => {
    // if no category or case found, redirect to library
    if (!libraryCategory || !libraryCase) {
      navigate(getLibraryPathWithCountry());
    }
  }, [libraryCase, libraryCategory, navigate]);

  if (!libraryCategory || !libraryCase) {
    return null;
  }

  return (
    <>
      <Helmet>
        <title>{t('pages.library')}</title>
      </Helmet>
      <Header />
      <div className={style.menuDivider} />
      <div className={style.marginWrapper}>
        <div className={style.heroDescriptionWrapper}>
          <LibraryHero
            breadcrumbs={[
              { name: 'Library', url: getLibraryPathWithCountry() },
              {
                name: libraryCategory.name,
                url: getLibraryPathWithCountry(
                  `/${convertToKebabCase(libraryCategory.name)}`
                ),
              },
              { name: 'Case' },
            ]}
            description={libraryCase.name}
          />
          <LibraryTextWrapper case={libraryCase} />
        </div>
        <NeedHelpCard />
        <LibraryCarousel
          title={`Explore issues relating to ${libraryCategory.name}`}
          cases={libraryCategory.cases.filter(
            (c) => c.name !== libraryCase.name
          )}
          className={style.carouselContainer}
        />
      </div>

      <Footer className={style.noFooterMargin} />
    </>
  );
};
