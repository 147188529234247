/* eslint-disable @stylistic/js/max-len */
import { LibraryCategory } from '../../../../types/library';
import cases from './cases.json';
import Icon from '../images/criminal-law.svg?react';
import bubbleImage from '../images/criminal-law-bubble.png';
import criminalImage from '../images/criminal.jpg';
import criminalImageFull from '../images/criminal-full.jpg';

const TITLE = 'Criminal law';

const criminalLaw: LibraryCategory = {
  name: TITLE,
  title: 'Introduction to Criminal law in New Zealand',
  image: criminalImage,
  previewImage: criminalImageFull,
  shortDescription:
    'Maintain order, protect individuals and property, and uphold justice.',
  description:
    'Criminal law encompasses laws related to offences against the state, individuals, and public safety. It includes a wide range of offences, from serious crimes like murder and assault to minor infractions such as traffic violations. <br/><br/>Criminal law aims to maintain public order, protect individuals and property, and uphold justice by enforcing laws and punishing offenders. It is governed by both state and federal legislation, with penalties and procedures varying across jurisdictions.',
  cases: cases.filter((c) => c.category.toLocaleLowerCase() === TITLE.toLocaleLowerCase()),
  Icon,
  bubbleImage,
  bodyText: [
    
  ],
};

export default criminalLaw;
