import loadEnv from '../../constants/env';
import { security } from './security';

export const enum RequestMethod {
  GET = 'GET',
  POST = 'POST',
  PUT = 'PUT',
  PATCH = 'PATCH',
  DELETE = 'DELETE',
}

export enum ServerResponseStatus {
  Success = 'success',
  Failure = 'failure',
}

const request = {
  raw: async (
    path: string,
    method: RequestMethod,
    body?: string | FormData,
    headers: Record<string, string> = {}
  ): Promise<unknown> => {
    try {
      let token = '';
      try {
        token = await security.getAccessToken();
      } catch (e) {
        // do nothing, no token available.
      }

      if (typeof body === 'string' && !headers['Content-Type']) {
        headers['Content-Type'] = 'application/json';
      }

      if (token && !headers.Authorization) {
        headers.Authorization = `Bearer ${token}`;
      }

      const controller = new AbortController();
      const signal = controller.signal;

      const response = await fetch(
        `${loadEnv.SERVER_ENPOINT || '/api'}${path}`,
        {
          credentials: 'include',
          headers,
          method,
          body,
          signal,
        }
      );

      const getJson = async () => {
        try {
          const json = await response.json();
          return json;
        } catch (e) {
          return {};
        }
      };
      if (response.status === 200) {
        return getJson();
      } else {
        if (response.status === 500) {
          // Retry the request up-to 3 times, with a 2 second delay between each retry
          let retries = 0;
          while (retries < 3) {
            await new Promise((resolve) => setTimeout(resolve, 2000));
            const retryResponse = await fetch(
              `${loadEnv.SERVER_ENPOINT || '/api'}${path}`,
              {
                credentials: 'include',
                headers,
                method,
                body,
              }
            );
            if (retryResponse.status === 200) {
              return getJson();
            }
            retries++;
          }
          let message = 'Request failed.';
          try {
            const json = await getJson(); 
            if (json.message) {
              message = json.message;
            }
          } catch (error) {
            // do nothing
          }
          return {
            status: ServerResponseStatus.Failure,
            message,
            timestamp: Date.now(),
          };
        } else if (response.status >= 400) {
          try {
            const json = await getJson();

            // we still want to fetch the error message
            return {
              status: ServerResponseStatus.Failure,
              message: json.message ?? json.error ?? 'Request failed.',
              timestamp: Date.now(),
            };
          } catch (error) {
            return {
              status: ServerResponseStatus.Failure,
              message: 'Request failed.',
              timestamp: Date.now(),
            };
          }
        }
        if (response.status === 401) {
          // should never get here since kinde handles this
        }
        return getJson();
      }
    } catch (error) {
      if (error.name === 'AbortError') {
        return {
          status: ServerResponseStatus.Failure,
          message: 'Request aborted.',
          timestamp: Date.now(),
        };
      }
      return {
        status: ServerResponseStatus.Failure,
        message: 'Request failed.',
        timestamp: Date.now(),
      };
    }
  },
  get: (path: string): Promise<unknown> => request.raw(path, RequestMethod.GET),
  post: (path: string, body = {}): Promise<unknown> =>
    request.raw(path, RequestMethod.POST, JSON.stringify(body)),
  put: (path: string, body = {}): Promise<unknown> =>
    request.raw(path, RequestMethod.PUT, JSON.stringify(body)),
  patch: (path: string, body = {}): Promise<unknown> =>
    request.raw(path, RequestMethod.PATCH, JSON.stringify(body)),
  delete: (path: string, body = {}): Promise<unknown> =>
    request.raw(path, RequestMethod.DELETE, JSON.stringify(body)),
  multipart: {
    post: (
      path: string,
      body: FormData,
      headers?: Record<string, string>
    ): Promise<unknown> => request.raw(path, RequestMethod.POST, body, headers),
    put: (path: string, body: FormData): Promise<unknown> =>
      request.raw(path, RequestMethod.PUT, body),
  },
};

export default request;
