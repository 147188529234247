/* eslint-disable @stylistic/js/max-len */
import { LibraryCategory } from '../../../../types/library';
import cases from './cases.json';
import bubbleImage from '../images/real-estate-bubble.png';
import realEstateImage from '../images/real-estate.jpg';
import realEstateImageFull from '../images/real-estate-full.jpg';
import Icon from '../images/real-estate.svg?react';

const TITLE = 'Real estate and property law';

const realEstate: LibraryCategory = {
  name: TITLE,
  title: 'Introduction to Real estate law in Ireland',
  image: realEstateImage,
  previewImage: realEstateImageFull,
  shortDescription:
    'Proactive measures to prevent disputes or prepare for action.',
  description:
    'Real estate law governs the rights and obligations of property owners, buyers, and tenants, ensuring fair dealings and protecting interests in real property. It encompasses a wide range of legal issues, from property transfers and leasing agreements to land use regulations and zoning laws.',
  cases: cases.filter((c) => c.category.toLocaleLowerCase() === TITLE.toLocaleLowerCase()),
  Icon,
  bubbleImage,
  bodyText: [
    
  ],
};

export default realEstate;
