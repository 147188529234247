import React, { FC, useMemo, useState, useEffect } from 'react';
import {
  LawyerConnection,
  Message,
  MessageType,
  File,
  Lawyer,
} from '@law-connect/types';
import {
  ChatMessageType,
  ChatMessageWrapper,
  formatLongDateTime,
} from '@law-connect/react-components';
import { PermissionMessage } from './permission-message';
import { ClientInductionMessage } from './client-induction';
import style from './style.module.less';
import placeholderImage from '../../assets/profile-no-img.png';
const DEFAULT_AVATAR_OFFSET = 88;

interface MessageWrapperProps {
  message: Message;
  messages: Message[];
  files: File[];
  hoverDelay?: number;
  connection: LawyerConnection;
  lawyers?: Lawyer[];
  connectionLawyerId?: string;
}

export const MessageWrapper: FC<MessageWrapperProps> = (props) => {
  const { message, 
    messages, 
    connection, 
    files, 
    lawyers, 
    connectionLawyerId 
  } = props;
  const hoverDelay = props.hoverDelay ?? 200;
  const avatarContainerRef = React.useRef<HTMLDivElement>(null);
  const [avatarApplyMargin, setAvatarApplyMargin] = useState(false);

  useEffect(() => {
    const checkHeight = () => {
      if (avatarContainerRef.current) {
        setAvatarApplyMargin(
          avatarContainerRef.current.offsetHeight > DEFAULT_AVATAR_OFFSET
        );
      }
    };

    checkHeight();
    window.addEventListener('resize', checkHeight);

    return () => window.removeEventListener('resize', checkHeight);
  }, []);

  const lawyer = useMemo(() => {
    if (message.from !== 'lawyer') {
      return undefined;
    }
    
    const lawyerId = message.lawyerId || connectionLawyerId;
    return lawyers?.find((lawyer) => lawyer.id === lawyerId);
  }, [message.lawyerId, connectionLawyerId, lawyers, message.from]);

  const senderName = useMemo(() => {
    if (message.from === 'lawyer') {
      const lawyerId = message.lawyerId || connectionLawyerId;
      const lawyer = lawyers?.find((l) => l.id === lawyerId);
      return lawyer ? `${lawyer.firstName} ${lawyer.lastName}` : null;
    }

    return 'You';
  }, [message.from, message.lawyerId, lawyers]);

  if (
    message.type === MessageType.Permission ||
    message.type === MessageType.ClientIntakeForm
  ) {
    const MessageComponent =
      message.type === MessageType.Permission
        ? PermissionMessage
        : ClientInductionMessage;
    return (
      <div ref={avatarContainerRef} className={`${style.chatMessageContainer} 
      ${message.from === 'client' ? style.sender : ''}`}>
        <MessageComponent
          messages={messages}
          message={message}
          connection={connection}
          avatar={<img
            src={lawyer?.image}
            alt={lawyer?.firstName}
            className={style.avatarIcon}
            onError={({ currentTarget }) => {
              currentTarget.onerror = null;
              currentTarget.src = placeholderImage;
            }}
          />}
          avatarClassName={`
            ${style.avatarIconContainer}
          `}
          sender={
            senderName ? (
              <div>
                { senderName }
              </div>
            ) : null
          }
          lawyerFirstName={lawyer?.firstName}
        />
      </div>
    );
  } else if (
    message.type === MessageType.Chat ||
    message.type === MessageType.Location ||
    message.type === MessageType.Confirmation
  ) {
    if (message.content) {
      return (
        <div ref={avatarContainerRef} className={`${style.chatMessageContainer} 
          ${message.from === 'client' ? style.sender : ''}`}>
          <ChatMessageWrapper
            messageBody={message.content}
            type={
              message.from === 'lawyer'
                ? ChatMessageType.Receiver
                : ChatMessageType.Sender
            }
            timestamp={
              message.createdAt ? formatLongDateTime(message.createdAt) : null
            }
            avatarLeft={
              message.from === 'lawyer' ? (
                <img
                  src={lawyer?.image}
                  alt={lawyer?.firstName}
                  className={style.avatarIcon}
                  onError={({ currentTarget }) => {
                    currentTarget.onerror = null;
                    currentTarget.src = placeholderImage;
                  }}
                />
              ) : null
            }
            avatarClassName={`
              ${style.avatarIconContainer}
            `}
            sender={
              senderName ? (
                <div>
                  { senderName }
                </div>
              ) : null
            }
            hoverDelay={hoverDelay}
            className={`${style.messageWrapper} ${
              message.from === 'lawyer'
                ? style.noBottomBorderRadiusReceiver
                : style.noBottomBorderRadiusSender
            }`}
          />
        </div>
      );
    } else if (message.fileIds?.length) {
      const messageFiles = message.fileIds.map((fileId) =>
        files.find((f) => f.id === fileId)
      );
      return (
        <div ref={avatarContainerRef} className={`${style.chatMessageContainer} 
          ${message.from === 'client' ? style.sender : ''}`}>
          <ChatMessageWrapper
            key={message.id}
            messageBody={message.content || ''}
            type={
              message.from === 'lawyer'
                ? ChatMessageType.Receiver
                : ChatMessageType.Sender
            }
            timestamp={
              message.createdAt ? formatLongDateTime(message.createdAt) : null
            }
            files={messageFiles}
            fileClassName={
              message.from === 'lawyer'
                ? style.lawyerFileName
                : style.clientFileName
            }
            filePreviewClassName={
              message.from === 'lawyer'
                ? style.lawyerFilePreview
                : style.clientFilePreview
            }
            className={`${style.messageWrapper} ${
              message.from === 'lawyer'
                ? style.noBottomBorderRadiusReceiver
                : style.noBottomBorderRadiusSender
            }`}
            avatarLeft={
              message.from === 'lawyer' ? (
                <img
                  src={lawyer?.image}
                  alt={lawyer?.firstName}
                  className={style.avatarIcon}
                  onError={({ currentTarget }) => {
                    currentTarget.onerror = null;
                    currentTarget.src = placeholderImage;
                  }}
                />
              ) : null
            }
            avatarClassName={`
              ${style.avatarIconContainer}
            `}
            sender={
              senderName ? (
                <div>
                  { senderName }
                </div>
              ) : null
            }
            fixedPositioning={true}
          />
        </div>
      );
    }
  }
};
