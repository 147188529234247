import React, {
  createRef,
  CSSProperties,
  FC,
  LegacyRef,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import style from './style.module.less';

import ArrowRightIcon from '../../../assets/icons/arrow-right.svg?react';
import ArrowUpIcon from '../../../assets/icons/arrow-up.svg?react';
import ArrowDownIcon from '../../../assets/icons/arrow-down.svg?react';


import { Link } from 'react-router-dom';
import { convertToKebabCase } from '../../../utils/case-conversion';
import { useTranslation } from 'react-i18next';
import { LawyerImage } from '../lawyer-images';
import { isTouchDevice, useScreenSize, WidthType } from '../../../hooks/use-is-mobile';
import { getLibraryPathWithCountry } from '../../../utils/get-library-path-with-country';
import { Carousel } from '@law-connect/react-components';
import { CardWidthSizes } from '../../../constants';
import CONTENT from '../../../containers/library/assets';

// time to take for the progress to reach 100%


interface Props {
  lawyerImage: LawyerImage;
}

export const HomeCaseStudies: FC<Props> = (props) => {
  const { lawyerImage } = props;
  const { t, i18n } = useTranslation();
  const language = i18n.language;
  const screenSize = useScreenSize();
  const [isInDiv, setIsInDiv] = useState(false);
  const [columnCount, setColumnCount] = useState(undefined);
  const [cardWidth, setCardWidth] = useState(undefined);
  const isTabletOrMobile = isTouchDevice();

  const getItems = useMemo(() => {
    return CONTENT.map((item) => {
      return (
        <Link
          key={item.name}
          className={style.carouselItem}
          to={`${getLibraryPathWithCountry()}/${convertToKebabCase(item.name)}`}
        >
          <img
            src={item.image}
            alt={item.name}
            className={style.carouselImage}
          />
          <div className={style.carouselOverlay} />
          <div className={style.carouselTitle}>{item.name}</div>
        </Link>
      );
    });
  }, [t]);

  useEffect(() => {
    if (
      screenSize === WidthType.LargeDesktop ||
      screenSize === WidthType.SmallDesktop
    ) {
      setColumnCount(5);
      setCardWidth(undefined);
    } else if (screenSize === WidthType.Tablet) {
      setColumnCount(3);
      setCardWidth(undefined);
    } else if (screenSize === WidthType.Mobile) {
      setColumnCount(undefined);
      setCardWidth(CardWidthSizes.Mobile);
    }
  }, [screenSize]);

  const onMouseEnter = useCallback(() => setIsInDiv(true), []);
  const onMouseLeave = useCallback(() => setIsInDiv(false), []);

  if (!language.includes('en')) return null;

  return (
    <div
      className={style.caseStudiesContainer}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <div className={style.backgroundWrapper}>
        <div
          className={style.backgroundContainer}
          style={{ background: lawyerImage.homeColours.backgroundColor }}
        />
        <div className={style.lawyerIconContainer}>
          <img
            src={lawyerImage.homeColours.icon}
            alt='lawyer'
            className={style.lawyerIcon}
          />
        </div>
      </div>

      <div className={style.title}>Explore our legal knowledge base</div>
      <div
        className={style.contentContainer}
        style={
          {
            '--carousel-scroll-bar-active-color':
              lawyerImage.homeColours.primaryColor,
          } as CSSProperties
        }
      >
        <Carousel
          items={getItems}
          offsetWidth={40}
          columnCount={columnCount}
          cardWidth={cardWidth}
          classNames={{
            container: style.carouselContainer,
            cardsContainer: style.cardsContainer,
            actionBar: `${style.actionBar} ${
              isTabletOrMobile ? '' : style.noTouch
            } ${isInDiv ? '' : style.outsideDiv}`,
            arrowButtonContainer: `${style.arrowButtonContainer} ${
              isTabletOrMobile ? style.noControl : ''
            }`,
          }}
          showProgressBar={
            screenSize === WidthType.Mobile || screenSize === WidthType.Tablet
          }
        />
      </div>
      <Link className={style.browseContainer} to={getLibraryPathWithCountry()}>
        <div className={style.browseTitle}>Browse the library</div>
        <div className={style.arrowContainer}>
          <ArrowRightIcon className={style.arrowIcon} />
        </div>
      </Link>
    </div>
  );
};
