import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import selectors from '../../../redux/selectors';
import {
  NarrowWrapper,
  WrapperOffsetSide,
} from '../../../components/narrow-wrapper';
import { useTranslation } from 'react-i18next';
import { MessageType, Question } from '@law-connect/types';
import styles from './style.module.less';
import { Footer } from '../../../components/footer';
import { actions } from '../../../redux/slices';
import { SectionedForm } from '@law-connect/react-components';
import { LogoImageComponent } from '../../../components/header/logo';
import PencilIcon from '../../../assets/icons/pencil.svg?react';
import { toast } from 'react-toastify';
import { DEFAULT_LANGUAGE_CODE } from '../../../../translations/locales';
import { addLanguage, findBestMatchLanguage } from '../../../i18n';

interface Props {}
export const LawyerConnectionClientIntakeForm: React.FC<Props> = () => {
  const { id } = useParams<{ id: string }>();

  const dispatch = useAppDispatch();

  const connection = useAppSelector(
    selectors.lawyerConnection.getConnectionById(id)
  );
  const prematter = useAppSelector(
    selectors.prematter.getById(connection?.prematterId)
  );
  const form = useAppSelector(
    selectors.lawyerConnection.getClientIntakeForm(id)
  );
  const messages = useAppSelector(
    selectors.lawyerConnection.getMessagesById(id)
  );

  const [wrappedLanguage, setWrappedLanguage] = useState<string | undefined>(
    undefined
  );

  const { t } = useTranslation(undefined, {
    lng: wrappedLanguage,
  });

  const clientIntakeCompleted = useMemo(
    () =>
      messages?.find(
        (m) => m.type === MessageType.ClientIntakeForm && m.from === 'client'
      ),
    [messages]
  );

  const onChange = (questions: Question[]) => {
    dispatch(
      actions.lawyerConnection.updateClientIntakeForm({
        connectionId: id,
        form: {
          ...form,
          questions,
        },
      })
    );
  };

  const onSubmit = async (questions: Question[]) => {
    // We are assuming that the onChange has already been called & the form server-side is the latest
    try {
      dispatch(actions.lawyerConnection.pollMessages({ id }));
      await dispatch(
        actions.lawyerConnection.markClientIntakeFormAsCompleted({
          connectionId: id,
        })
      ).unwrap();
      toast.success(t('toast.submitted-successfully'));

      // Close the browser tab
      window.close();
    } catch (error) {
      console.error('submit client intake form', error);
      toast.error(t('toast.failed-to-send'));
    }
  };

  useEffect(() => {
    if (!form) {
      dispatch(
        actions.lawyerConnection.fetchClientIntakeForm({ connectionId: id })
      );
    }
  }, [dispatch, form, id]);

  useEffect(() => {
    dispatch(actions.lawyerConnection.fetchById({ id }));
    dispatch(actions.lawyerConnection.fetchMessages({ id }));
    dispatch(actions.prematter.fetch());
  }, [dispatch, id]);

  useEffect(() => {
    if (prematter?.language) {
      const asyncWrapper = async () => {
        const wrapped = await addLanguage(prematter.language);
        setWrappedLanguage(wrapped);
      };
      asyncWrapper();
    }
  }, [prematter?.language]);

  if (!form) {
    return null;
  }

  return (
    <div className={styles.clientIntakeFormWrapper}>
      <div className={styles.clientIntakeFormContent}>
        <div className={styles.menu}>
          <LogoImageComponent />
        </div>
        <div className={styles.header}>
          <div className={styles.icon}>
            <PencilIcon />
          </div>
          <div className={styles.title}>{t('intake.common.questionnaire')}</div>
          <div className={styles.description}>
            {t('intake.common.description')}
          </div>
        </div>
        <div className={styles.clientIntakeFormBody}>
          <NarrowWrapper
            offset={WrapperOffsetSide.LEFT}
            offsetClassName={styles.fullWidth}
          >
            <SectionedForm
              title={t('intake.common.categories')}
              form={form}
              onChange={onChange}
              onSubmit={onSubmit}
              onSubmitLabel={t('intake.common.submit-label')}
              disabled={
                isNaN(form.completedAt) || clientIntakeCompleted != null
              }
              language={findBestMatchLanguage(
                prematter?.language
              )}
            />
          </NarrowWrapper>
        </div>
        <Footer />
      </div>
    </div>
  );
};
