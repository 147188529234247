import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import Markdown from 'react-markdown';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Header } from '../../../components/header';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import selectors from '../../../redux/selectors';
import { PrematterHeader } from '../../../components/prematter-header';
import { NarrowWrapper } from '../../../components/narrow-wrapper';
import style from './style.module.less';
import { Footer } from '../../../components/footer';
import { QuestionCards } from '../../../components/question-card';
import CloseIcon from '../../../assets/icons/cross-circle.svg?react';
import { useCookies } from 'react-cookie';
import { PrintReportIcon } from './print';
import { AddQuestionComponent } from './add-question';
import { VerificationBanner } from '../verification-banner';
import { MAX_ALLOWED_QUESTION } from '../../../constants';
import { NextStepsList } from './next-steps';
import { isVerifyDisabled } from '../../../utils/is-verify-disabled';
import { NextStepCard } from '../../../components/next-step-card';
import { LegalCaseState } from '@law-connect/types';
import { useMobile } from '../../../hooks/use-is-mobile';
import { VerifyModalComponent } from '../../../components/verify-modal';
import { actions } from '../../../redux/slices';
import ShieldTickIcon from '../../../assets/icons/shield-tick.svg?react';
import { ReportCard } from '../../../components/report-card';
import { getAiLawyerImage } from '../../../utils/ai-lawyer';
import {
  AnchorComponent,
  answersAnchor,
  conclusionAnchor,
  overviewAnchor,
} from './anchor';
import { addLanguage, findBestMatchLanguage } from '../../../i18n';

interface Props {}
export const PrematterReport: React.FC<Props> = () => {
  const { id } = useParams<{ id: string }>();

  const isMobile = useMobile();

  const [showModal, setShowModal] = useState(false);

  const dispatch = useAppDispatch();

  const ref = useRef<HTMLDivElement>(null);
  // we need this mini hack because the state doesnt rerender on ref set so we need a variable change to rerender
  const [cookies, setCookie] = useCookies(['more-info-tooltip-hidden']);
  const [moreInfoTooltipHidden, setMoreInfoTooltipHidden] = React.useState(
    cookies['more-info-tooltip-hidden'] !== undefined
  );
  const [lawyerPic, setlawyerPic] = useState(null);
  const prematter = useAppSelector(selectors.prematter.getById(id));
  const canBeVerified = useAppSelector(selectors.prematter.canBeVerified(id));
  const connections = useAppSelector(
    selectors.lawyerConnection.getConnectionsByPrematterId(id)
  );

  const [prematterQuestions, setPrematterQuestions] = useState(
    prematter.context.questions ?? []
  );

  const hasReplied = connections?.some(
    (c) => c.messages?.filter((m) => m.from === 'client').length > 0
  );
  const [wrappedLanguage, setWrappedLanguage] = useState<string | undefined>(
    undefined
  );

  const { t } = useTranslation(undefined, {
    lng: wrappedLanguage,
  });
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(actions.prematter.fetchById({ id }));
    dispatch(actions.lawyerConnection.fetchByPrematterId({ prematterId: id }));
  }, [dispatch, id]);

  const verifiedLabel = useMemo(() => {
    if (prematter.state === LegalCaseState.ReportReady) {
      return t('verify.verify-now');
    } else if (prematter.state === LegalCaseState.VerificationPending) {
      return t('verify.pending');
    } else if (prematter.state === LegalCaseState.Verified) {
      return t('prematter.complete');
    } else {
      return null;
    }
  }, [prematter?.state, t]);

  const verifyAction = useCallback(() => {
    dispatch(actions.prematter.sendVerify({ id: prematter.id }));
    setShowModal(false);
  }, [dispatch, prematter?.id]);

  useEffect(() => {
    if (prematter?.context?.questions && !prematterQuestions.length) {
      setPrematterQuestions(prematter.context.questions);
    }
  }, [prematter, prematterQuestions]);

  useEffect(() => {
    // The lawyer image is set in the prematter router if undefined, so should always exist.
    if(prematter?.aiLawyer){
      const aiLawyer = getAiLawyerImage({
        aiLawyer: prematter.aiLawyer,
        angled: true,
      });
      setlawyerPic(aiLawyer);
    }
  }, [prematter?.aiLawyer]);

  useEffect(() => {
    if (prematter?.language) {
      const asyncWrapper = async () => {
        const wrapped = await addLanguage(prematter.language);
        setWrappedLanguage(wrapped);
      };
      asyncWrapper();
    }
  }, [prematter?.language]);


  const showingNextSteps = useMemo(() => 
    canBeVerified || isVerifyDisabled(prematter.state)
  , [canBeVerified, prematter.state]);
  if (!prematter) {
    return null;
  }

  // TODO: bug not sure why but when changing screens the ref is not set on mount
  return (
    <>
      <div
        ref={(r) => {
          ref.current = r;
        }}
        className={style.reportTopWrapper}
      >
        <Header />
        <PrematterHeader prematter={prematter} noExtension />

        <div className={style.prematterContent}>
          <div>
            <NarrowWrapper contentClassName={style.promoCard}>
              <VerificationBanner prematter={prematter} />
            </NarrowWrapper>
            <AnchorComponent showingNextSteps={showingNextSteps}/>
          </div>
          <ReportCard
            id={overviewAnchor}
            title={t('prematter.overview')}
            image={<img src={lawyerPic} alt='AI Lawyer' />}
            action={<PrintReportIcon prematterId={prematter.id} />}
          >
            <Markdown className={style.text}>{prematter.description}</Markdown>
          </ReportCard>

          <ReportCard title={t('prematter.answers')} id={answersAnchor}>
            {moreInfoTooltipHidden ||
            isVerifyDisabled(prematter.state) ? null : (
                <div className={style.needMoreInfo}>
                  <div className={style.needMoreInfoTitle}>
                    {t('prematter.more-info-tooltip.title')}
                  </div>
                  <div
                    className={style.close}
                    onClick={() => {
                      setCookie('more-info-tooltip-hidden', 'true', {
                        path: '/',
                        maxAge: 60 * 60 * 24 * 365,
                      });
                      setMoreInfoTooltipHidden(true);
                    }}
                  >
                    {t('confirm.close')}
                    <CloseIcon className={style.closeIcon} />
                  </div>
                  <div className={style.youCanEdit}>
                    <span className={style.desktopTitle}>
                      {t('prematter.more-info-tooltip.title')}&nbsp;
                    </span>
                    <span
                      dangerouslySetInnerHTML={{
                        __html: t('prematter.more-info-tooltip.explanation', {
                          interpolation: { escapeValue: false },
                        }),
                      }}
                      className={style.youCanEditText}
                    />
                  </div>
                </div>
              )}
            <QuestionCards
              questions={prematter.context.questions || []}
              prematterState={prematter.state}
            />
            {prematterQuestions?.length < MAX_ALLOWED_QUESTION ? (
              <>
                <AddQuestionComponent
                  id={prematter?.id}
                  prematterState={prematter?.state}
                  questionsLength={prematter?.context?.questions?.length}
                  language={findBestMatchLanguage(prematter?.language)}
                />
                {prematter.context?.questions?.length >=
                MAX_ALLOWED_QUESTION ? (
                    <div className={`${style.maxQuestion}`}>
                      {t('prematter.max-questions')}
                    </div>
                  ) : null}
              </>
            ) : null}
          </ReportCard>

          {canBeVerified ||
          prematter.state === LegalCaseState.VerificationPending ||
          (isVerifyDisabled(prematter.state) && !connections?.length) ? (
              <NarrowWrapper
                contentClassName={style.promoCard}
                className={style.verifyCardWrapper}
              >
                <NextStepCard
                  className={style.verifyCard}
                  image={{
                    src: `/next-steps/verify${isMobile ? '-mobile' : ''}.jpg`,
                    icon: <ShieldTickIcon width={24} height={24} />,
                    label: t('verify.verify'),
                  }}
                  title={t('verify.verify-case')}
                  features={[
                    t('verify.verify-case-description.0'),
                    t('verify.verify-case-description.1'),
                    t('verify.verify-case-description.2'),
                  ]}
                  complete={prematter.state !== LegalCaseState.ReportReady}
                  button={{
                    label: verifiedLabel,
                    onClick: () => setShowModal(true),
                  }}
                  rounded={true}
                />
              </NarrowWrapper>
            ) : connections?.length > 0 &&
            prematter.state === LegalCaseState.Verified ? (
                <NarrowWrapper 
                  contentClassName={style.promoCard}
                  className={style.verifyCardWrapper}
                >
                  <NextStepCard
                    className={style.verifyCard}
                    image={{
                      // eslint-disable-next-line @stylistic/js/max-len
                      src: `/next-steps/connect${isMobile ? '-mobile' : ''}.jpg`,
                      icon: <ShieldTickIcon width={24} height={24} />,
                      label: t('connect.title'),
                    }}
                    title={t('connect.get-help')}
                    features={[
                      t('connect.get-help-description.0'),
                      t('connect.get-help-description.1'),
                      t('connect.get-help-description.2'),
                    ]}
                    complete={hasReplied}
                    button={{
                      label: !hasReplied
                        ? t('connect.title')
                        : t('prematter.complete'),
                      onClick: () => navigate(`/case/${prematter.id}/connect`),
                    }}
                    rounded={true}
                  />
                </NarrowWrapper>
              ) : null}

          <ReportCard
            id={conclusionAnchor}
            title={t('prematter.conclusion')}
            image={<img src={lawyerPic} alt='AI Lawyer' />}
            className={style.conclusion}
          >
            <Markdown className={style.text}>{prematter.nextSteps}</Markdown>
          </ReportCard>
        </div>
        <div className={style.disclaimerWrapper}>
          <div className={style.disclaimer}>
            <div
              dangerouslySetInnerHTML={{
                __html: t('auth.disclaimer'),
              }}
            />
          </div>
        </div>
        {showingNextSteps ? (
          <NextStepsList prematter={prematter} connections={connections} />
        ) : null}
        <Footer />
      </div>

      <VerifyModalComponent
        isOpen={showModal}
        close={() => setShowModal(false)}
        toVerify={true}
        verifyAction={verifyAction}
      />
    </>
  );
};
