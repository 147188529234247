/* eslint-disable @stylistic/js/max-len */
import { LibraryCategory } from '../../../../types/library';
import cases from './cases.json';
import bubbleImage from '../images/estates-probate-bubble.png';
import estatesImage from '../images/estates.jpg';
import estatesImageFull from '../images/estates-full.jpg';
import Icon from '../images/estates-probate.svg?react';

const TITLE = 'Estate and probate law';

const estatesAndProbates: LibraryCategory = {
  name: TITLE,
  title: 'Introduction to Estates and probates in Australia',
  image: estatesImage,
  previewImage: estatesImageFull,
  shortDescription: 'Understanding estate planning and probate processes.',
  description:
    'Estates comprise all valuable possessions owned at the time of an individual\'s death, including bank accounts, property, shares, superannuation funds, and collections like jewellery. Proper estate planning ensures these assets are distributed according to your wishes, not just through a will. <br/><br/> In Australia, you must be 18 or older for your estate plan to be legally binding. An estate plan can include documents such as a will, superannuation nominations, trusts, life insurance, powers of attorney, guardianship arrangements, and advance care directives. Note that estate legislation varies slightly across Australian states.',
  cases: cases.filter(
    (c) => c.category.toLocaleLowerCase() === TITLE.toLocaleLowerCase()
  ),
  Icon,
  bubbleImage,
  bodyText: [
    {
      header: 'What is probate in Australia?',
      body: 'Probate is the legal process of validating a deceased person\'s last will, confirming it as their final and unaltered testament. This grants the executor legal authority to access and distribute the estate according to the will, facilitating cooperation from financial institutions and other entities. Probate protects executors by reducing personal liability in asset distribution, ensures debts and taxes are settled before beneficiaries receive their inheritance, and provides a framework for resolving disputes over the will. While not all estates require probate—depending on the assets involved and their value—it is often essential for larger or more complex estates. Probate legislation can vary slightly between Australian states.',
    },
    {
      header: 'Estate vs. probate planning',
      body: `<b>Estate planning</b>
        <ul>
          <li>Having a current will</li>
          <li>Nominating your executors to manage the administration of your estate</li>
          <li>Establishing estate planning strategies that suit your individual situation and secure your assets</li>
          <li>Knowing how legal practitioners can assist in your estate planning</li>
        </ul>
        <br />
        <b>Probate planning</b>
        <ul>
          <li>Establishing the assets and liabilities of the estate first </li>
          <li>Gathering the original will document, death certificate, and paying any appropriate fees </li>
          <li>Applying for probate </li>
          <li>Issuance of the grant of probate</li>
          <li>Verifying the grant of probate with the holders of the assets and valuables</li>
        </ul>`,
    },
    {
      header: 'What is the difference between will and estate planning?',
      body: 'Often mistaken as the same concepts, they are differentiated based on whether you are alive at the given time. A will is based on how you desire to have your assets and valuables dealt with once you pass away. An estate plan considers your circumstances and your children’s situation before your passing. ',
    },
    {
      header: 'What are the different types of wills and trusts?',
      body: '<ul><li><b>Testamentary trust</b>: Created through a will and becomes active only after the individual’s death.</li><li><b>Discretionary trust</b>: Also known as a family trust, this arrangement allows trustees to decide how assets are allocated among beneficiaries.</li><li><b>Unit trust</b>: Individuals pool their funds into a collective investment managed by trustees.</li><li><b>Hybrid trust</b>: Blends the features of both discretionary and unit trusts, offering beneficiaries increased flexibility and potential tax advantages.</li><li><b>Living trusts</b>: Established during an individual’s lifetime, these trusts can serve to protect assets or help in bypassing the probate process, which legally validates wills.</li></ul>',
    },
    {
      header: 'What are the benefits and risks of estate planning?',
      body: '<ul><li>Protects your assets and valuables after your passing</li><li>Ensures the beneficiaries are provided for</li><li>Plans ahead for any undesired circumstances that could occur</li><li>Reduces future tax costs</li></ul>',
    },
    {
      header: 'Challenges in the probate process',
      body: '<ul><li>The will may go missing</li></ul>'
    }
  ],
};

export default estatesAndProbates;
