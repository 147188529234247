import { Prematter } from '@law-connect/types';
import React, {
  FC,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { Card, CardPlaceholder } from '../../components/card';
import { getIcon } from './get-icon';
import { getTitle } from './use-get-title';
import { useNavigate } from 'react-router-dom';
import style from './style.module.less';
import { isPrematterComplete } from '../../utils/is-prematter-complete';
import { Carousel, CarouselRef } from '@law-connect/react-components';
import {
  isTouchDevice,
  useScreenSize,
  WidthType,
} from '../../hooks/use-is-mobile';
import AddIcon from '../../assets/icons/add.svg?react';
import { CAROUSEL_CARD_SIZES } from '../../constants';

interface Props {
  prematters: Prematter[];
}

export const RecentCardsComponent: FC<Props> = (props) => {
  const { prematters } = props;
  const { t } = useTranslation();
  const navigate = useNavigate();
  const cardsScrollRef = useRef<CarouselRef>(null);
  const [expectedCards, setExpectedCards] = useState<number | null>(0);
  const [isInDiv, setIsInDiv] = useState(false);
  const isTabletOrMobile = isTouchDevice();

  const onMouseEnter = useCallback(() => setIsInDiv(true), []);
  const onMouseLeave = useCallback(() => setIsInDiv(false), []);

  const screenType = useScreenSize();
  const cardWidth = useMemo(() => {
    if (screenType === WidthType.Mobile) {
      return CAROUSEL_CARD_SIZES.mobile;
    } else if (screenType === WidthType.Tablet) {
      return CAROUSEL_CARD_SIZES.tablet;
    } else {
      return CAROUSEL_CARD_SIZES.desktop;
    }
  }, [screenType]);

  const getTitleWrapper = useCallback(
    (prematter: Prematter) => {
      return getTitle(t, prematter);
    },
    [t]
  );

  const startNewChat = useCallback(() => {
    navigate('/chat', { state: { newCase: true } });
  }, [navigate]);
  const handleCardResize = useCallback(() => {
    const getExpectedCards =
      cardsScrollRef.current?.getExpectedCards(cardWidth);
    if (getExpectedCards != null) {
      setExpectedCards(getExpectedCards);
    }
  }, [cardWidth]);

  useEffect(() => {
    handleCardResize();
    window.addEventListener('resize', handleCardResize);
    return () => window.removeEventListener('resize', handleCardResize);
  }, [handleCardResize]);

  const cardBody = useMemo(() => {
    const NewCase = (
      <CardPlaceholder
        onClick={startNewChat}
        className={style.placeholderCard}
        key='new-case'
      >
        <AddIcon className={style.addIcon} />
        {t('account.start-new-case')}
      </CardPlaceholder>
    );
    // 3 situations -> no cases, less than expected cards, more than expected cards
    const cardsArray = [NewCase];
    const placeholderCards = expectedCards - prematters.length;
    if (prematters?.length) {
      prematters.forEach((prematter, index) =>
        cardsArray.push(
          <Card
            id={index}
            key={prematter.id}
            icon={getIcon(prematter.state)}
            title={getTitleWrapper(prematter)}
            timestamp={prematter.updatedAt}
            buttonLabel={
              isPrematterComplete(prematter.state)
                ? t('account.view')
                : t('account.edit')
            }
            onClick={() => {
              isPrematterComplete(prematter.state)
                ? navigate(`/case/${prematter.id}`)
                : navigate(`/case/${prematter.id}/chat`);
            }}
          />
        )
      );
    }
    if (placeholderCards > 0) {
      Array.from({ length: placeholderCards }, (_, index) => {
        cardsArray.push(
          <CardPlaceholder
            key={index}
            className={style.placeholderCard}
            placeholder
          />
        );
      });
    }

    return cardsArray;
  }, [expectedCards, startNewChat, t, prematters, getTitleWrapper, navigate]);

  return (
    <div
      className={style.carouselContainer}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <Carousel
        ref={cardsScrollRef}
        cardWidth={cardWidth}
        showProgressBar={expectedCards <= prematters.length}
        items={cardBody}
        classNames={{
          cardsContainer: `${style.cardsContainer} ${
            expectedCards > prematters.length ? style.noCases : ''
          }`,
          cardsPosition: style.cardsPosition,
          actionBar: `${style.actionBar} ${
            isTabletOrMobile ? '' : style.noTouch
          } ${isInDiv ? '' : style.outsideDiv}`,
          arrowButtonContainer: `${style.carouselButton} ${
            isTabletOrMobile ? style.noControl : ''
          }`,
        }}
      />
    </div>
  );
};
