import React, {
  ChangeEvent,
  createRef,
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { createPortal } from 'react-dom';
import style from './style.module.less';
import { useOnClickOutside } from '../../hooks/use-on-click-outside';
import CloseIcon from '../../assets/icons/cross-circle.svg?react';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import selectors from '../../redux/selectors';
import { CheckboxInput } from '../checkbox';
import { actions } from '../../redux/slices';
import { useParams } from 'react-router-dom';
import PhoneInput, { isPossiblePhoneNumber } from 'react-phone-number-input';
import { toast } from 'react-toastify';
import 'react-phone-number-input/style.css';

export interface PermissionModalRef {
  closeModal: () => void;
  openModal: () => void;
}

interface Props {
  requestMessageId: string;
  onClose?: () => void;
}

export const PermissionModal = forwardRef<PermissionModalRef | null, Props>(
  (props, ref) => {
    const { onClose, requestMessageId } = props;

    const { id } = useParams<{ id: string }>();
    const user = useAppSelector(selectors.user.getUser());
    const [firstName, setFirstName] = useState(user.firstName ?? '');
    const [lastName, setLastName] = useState(user.lastName ?? '');
    const [email, setEmail] = useState(user.email ?? '');
    const [phone, setPhone] = useState(user.phone ?? '');
    const [consented, setConsented] = useState(false);
    const [open, setOpen] = useState(false);
    const [invalidPhoneNumber, setInvalidPhoneNumber] = useState(false);

    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const modalRef = createRef<HTMLDivElement>();
    const closeModal = useCallback(() => {
      setOpen(false);
      onClose && onClose();
    }, [onClose]);
    const openModal = useCallback(() => setOpen(true), []);
    useOnClickOutside(modalRef, closeModal);

    useImperativeHandle(ref, () => ({
      closeModal,
      openModal,
    }));

    useEffect(() => {
      // stop scrolling when modal is open
      if (open) {
        document.body.style.overflow = 'hidden';
      } else {
        document.body.style.overflow = 'auto';
      }
    }, [open]);

    useEffect(() => {
      if (user) {
        user.firstName && setFirstName(user.firstName);
        user.lastName && setLastName(user.lastName);
        user.email && setEmail(user.email);
        user.phone && setPhone(user.phone);
      }
    }, [user]);

    const allowed: boolean = useMemo(
      () =>
        consented &&
        firstName.length &&
        lastName.length &&
        email.length &&
        phone.length
          ? true
          : false,
      [consented, email.length, firstName.length, lastName.length, phone.length]
    );

    const updateUserData = useCallback(() => {
      if (
        user.firstName !== firstName ||
        user.lastName !== lastName ||
        user.phone !== phone
      ) {
        dispatch(
          actions.user.update({
            firstName,
            lastName,
            phone,
          })
        );
      }
    }, [dispatch, user, firstName, lastName, phone]);

    const sendDeny = useCallback(() => {
      dispatch(
        actions.lawyerConnection.sendPermissionMessage({
          id,
          permissions: {
            prematterFiles: false,
            userDetails: false,
          },
          requestMessageId,
        })
      );

      // dispatch upate user data
      updateUserData();
      closeModal();
    }, [dispatch, id, closeModal, requestMessageId, updateUserData]);

    const sendAllow = useCallback(() => {
      // check if phone number is valid. If not, show error toast message and highlight the phone input field
      if (!isPossiblePhoneNumber(phone)) {
        setInvalidPhoneNumber(true);
        toast.error(t('toast.invalid-phone-number'));
        return;
      }

      dispatch(
        actions.lawyerConnection.sendPermissionMessage({
          id,
          permissions: {
            prematterFiles: true,
            userDetails: true,
          },
          requestMessageId,
        })
      );
      updateUserData();
      closeModal();
    }, [dispatch, id, closeModal, requestMessageId, updateUserData]);

    const setPhoneWrapper = useCallback(
      (value: string) => {
        setPhone(value ?? '');
        setInvalidPhoneNumber(false);
      },
      [setPhone]
    );

    const body = useMemo(
      () => (
        <div className={style.modalWrapper}>
          <div className={style.modalBody} ref={modalRef}>
            <div className={style.closeContainer}>
              <CloseIcon onClick={closeModal} className={style.closeIcon} />
            </div>

            <div className={style.header}>
              {t('account.permission-modal.share-information')}
            </div>
            <div className={style.inputBodyContainer}>
              <div className={style.inputContainer}>
                <div className={style.label}>
                  {t('account.edit-profile.first-name')}
                </div>
                <input
                  type='text'
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                  className={style.input}
                  readOnly={user?.firstName?.length ? true : false}
                />
              </div>
              <div className={style.inputContainer}>
                <div className={style.label}>
                  {t('account.edit-profile.last-name')}
                </div>
                <input
                  type='text'
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                  className={style.input}
                  readOnly={user.lastName?.length ? true : false}
                />
              </div>
              <div className={style.inputContainer}>
                <div className={style.label}>
                  {t('account.edit-profile.email')}
                </div>
                <input
                  type='text'
                  value={email}
                  readOnly={true}
                  className={style.input}
                />
              </div>
              <div className={style.inputContainer}>
                <div className={style.label}>
                  {t('account.edit-profile.phone')}
                </div>
                <div
                  className={`${style.phoneInputContainer}  
                    ${user?.phone?.length ? style.disabled : ''}
                    ${invalidPhoneNumber ? style.error : ''}`}
                >
                  <PhoneInput
                    onChange={setPhoneWrapper}
                    value={phone}
                    defaultCountry='AU'
                    disabled={user?.phone?.length ? true : false}
                    className={style.phoneInput}
                  />
                </div>
              </div>
            </div>
            <div className={style.divider} />
            <div className={style.consentContainer}>
              <div className={style.consentCard}>
                <CheckboxInput
                  checked={consented}
                  onChange={setConsented}
                  label={
                    // eslint-disable-next-line @stylistic/js/max-len
                    t('account.permission-modal.consent')
                  }
                  className={style.consentCheckbox}
                />
              </div>
            </div>
            <div className={style.divider} />
            <div className={style.footer}>
              <button className={`${style.notNowButton} 
                ${style.consentButton1}`} onClick={closeModal}>
                {t('account.permission-modal.not-now')}
              </button>
              <div className={`${style.floatRight} ${style.consentButton2}`}>
                <button className={style.denyButton} onClick={sendDeny}>
                  {t('account.permission-modal.deny')}
                </button>
                <button
                  className={style.allowButton}
                  onClick={sendAllow}
                  disabled={!allowed}
                >
                  {t('account.permission-modal.allow')}
                </button>
              </div>
            </div>
          </div>
        </div>
      ),
      [
        allowed,
        closeModal,
        consented,
        email,
        firstName,
        lastName,
        modalRef,
        phone,
        sendAllow,
        sendDeny,
        setPhoneWrapper,
        user?.firstName?.length,
        user.lastName?.length,
        user?.phone?.length,
        t,
      ]
    );

    return createPortal(open ? body : <></>, document.body);
  }
);
