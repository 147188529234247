import {
  useRef,
  useLayoutEffect,
  forwardRef,
  useState,
  useCallback,
  useEffect,
  Fragment,
  useImperativeHandle,
  useMemo,
} from 'react';
import styles from './style.module.less';
import {
  File as PrematterFile,
  LegalCaseState,
  Message,
  MessageType,
  Prematter,
  Question,
  AiLawyer,
} from '@law-connect/types';
import React from 'react';
import {
  ChatInput,
  formatDate,
  InlineForm,
  InputRef,
} from '@law-connect/react-components';
import useResizeObserver from 'use-resize-observer';
import { MessageRenderer } from './message-renderer';
import LoadingSvg from '../../../assets/icons/loading.svg?react';
import { useTranslation } from 'react-i18next';
import FAQComponent from '../../../components/faq';
import FolderIcon from '../../../assets/icons/folder.svg?react';
import { useMobile } from '../../../hooks/use-is-mobile';
import { ChatFile } from '../../../components/chat-files/file';
// eslint-disable-next-line @stylistic/js/max-len
import { FileUploading } from '../../../components/chat-history/uploading-files';
import { useAppDispatch } from '../../../redux/hooks';
import { actions } from '../../../redux/slices';
import { aiLawyerImages } from '../../../assets/lawyers/ai-lawyer-images';
import { Button } from '../../../components/button';
import { DEFAULT_LANGUAGE_CODE } from '../../../../translations/locales';
import { addLanguage, findBestMatchLanguage } from '../../../i18n';

export interface ChatElementProps extends React.HTMLAttributes<HTMLDivElement> {
  prematter: Prematter;
  messages: Message[];
  onSendMessage: (args: {
    type: MessageType;
    text?: string;
    files?: File[];
  }) => void;
  isLoading: boolean;
  isFileUploading: boolean;
  inputClassName?: string;
  withHover?: boolean;
  children?: React.ReactNode;

  addQuestion: (args: { question: string }) => void;
  addQuestionPending: boolean;
  addQuestionError?: string;

  // Form related props
  onFormFileUpload: (files: File[]) => void;
  onFormCompletion: (questions: Question[]) => void;
  files: PrematterFile[];

  disabled?: boolean;
  headerContainerRef?: React.RefObject<HTMLDivElement>;

  defaultAiLawyer?: AiLawyer;

  bottomOffset?: number;

  onComplete?: () => void;

  readOnly?: boolean;
}

export interface ChatElementRef {
  scrollToBottom: () => void;
  setMessage: (message: string) => void;
}

export const ChatElement = forwardRef<ChatElementRef, ChatElementProps>(
  (props, ref) => {
    const {
      prematter,
      onSendMessage,
      messages,
      isLoading,
      isFileUploading,
      children,
      inputClassName,
      withHover,
      bottomOffset = 0,
      addQuestion,
      addQuestionPending,
      addQuestionError,
      files,
      onFormFileUpload,
      onFormCompletion,
      onComplete,
      disabled,
      headerContainerRef,
      defaultAiLawyer,
      readOnly,
      ...elementProps
    } = props;

    const dispatch = useAppDispatch();

    const [wrappedLanguage, setWrappedLanguage] = useState<string | undefined>(
      undefined
    );

    const { t } = useTranslation(undefined, {
      lng: wrappedLanguage,
    });

    // Purely for next step card styling purposes
    const isMobile = useMobile();

    // we do not care about the value, we just need this to trigger the check for button ids
    const [formProgress, setFormProgress] = useState<number>(0);
    // Wraps around the entire chat element
    const chatRef = useRef<HTMLDivElement>(null);

    // This is the floating input
    const inputRef = useRef<InputRef>(null);

    // sticky = fixed to bottom of screen
    const [isSticky, setIsSticky] = useState(false);
    const stickyInputRef = useRef<HTMLDivElement>(null);

    const [isBelowChat, setIsBelowChat] = useState(false);

    const { ...stickyInputRefBounds } = useResizeObserver({
      ref: stickyInputRef,
      box: 'border-box', // we want to include padding
    });

    const [fileOver, setFileOver] = useState(false);

    const [selectedAiLawyer, setSelectedAiLawyer] = useState<AiLawyer | null>(
      prematter?.aiLawyer || null
    );

    const endRef = useRef<HTMLDivElement>(null);

    const postQuestionnaireMessage =
      prematter?.state === LegalCaseState.AuthCheck
        ? t('chat.complete-form-no-auth')
        : t('chat.complete-form-auth');

    const [showMobileTimestamps, setShowMobileTimestamps] = useState<{
      [id: string]: boolean;
    }>({});

    // callback: scrollToEnd, callbackDelay: 80
    const setShowMobileTimestamp = useCallback((id: string, state: boolean) => {
      setShowMobileTimestamps((prev) => ({ ...prev, [id]: state }));
    }, []);

    useEffect(() => {
      if (readOnly) {
        return;
      }
      if (!prematter?.id) {
        // Scroll to top of page (user reset session?)
        window.scrollTo(0, 0);
      } else {
        // Scroll to the bottom of the chat on new messages
        if (endRef.current) {
          // if the endRef is already in view, do not scroll
          const endRefRect = endRef.current.getBoundingClientRect();

          if (window.innerHeight >= endRefRect.bottom) {
            // Since we are already at the bottom of the chat or below it, do not scroll
            setIsBelowChat(true);
            return;
          }

          endRef.current?.scrollIntoView({
            behavior: 'smooth',
            block: 'end', // Aligns the element at the bottom of the viewport
            inline: 'nearest', // Aligns the element horizontally if necessary
          });
        }
      }
    }, [
      messages,
      isLoading,
      prematter,
      children,
      elementProps.style?.paddingBottom,
      readOnly,
    ]);

    useEffect(() => {
      if (readOnly) {
        return;
      }
      if (!prematter?.id) {
        // Scroll to top of page (user reset session?)
        window.scrollTo(0, 0);
      } else {
        // Scroll to the bottom of the chat on page load
        setTimeout(() => {
          // We have this in a timeout to ensure the chat has rendered
          endRef.current?.scrollIntoView({
            behavior: 'smooth',
            block: 'end', // Aligns the element at the bottom of the viewport
            inline: 'nearest', // Aligns the element horizontally if necessary
          });
        }, 50);
      }
    }, []);

    useEffect(() => {
      if (prematter?.aiLawyer) {
        setSelectedAiLawyer(prematter.aiLawyer);
      } else if (!prematter?.aiLawyer) {
        if (!selectedAiLawyer) {
          if (defaultAiLawyer) {
            setSelectedAiLawyer(defaultAiLawyer);
          } else {
            // select a random ai lawyer and set it for new chat sessions
            const randomAiLawyer =
              aiLawyerImages[Math.floor(Math.random() * aiLawyerImages.length)];
            setSelectedAiLawyer(randomAiLawyer.aiLawyer);
          }
        }

        // Update the ai lawyer once a prematter id is available
        if (prematter?.id && selectedAiLawyer) {
          dispatch(
            actions.prematter.updateAiLawyer({
              id: prematter.id,
              aiLawyer: selectedAiLawyer,
              setDefault: false,
            })
          );
        }
      }
    }, [dispatch, prematter?.aiLawyer, prematter?.id, defaultAiLawyer]);

    // if the stickyInputRef is < 20px from the bottom of the screen
    // position it absolute
    useLayoutEffect(() => {
      const handleScroll = () => {
        if (!chatRef.current || !stickyInputRef.current) {
          return;
        }

        const chatRefRect = chatRef.current.getBoundingClientRect();

        // Bottom of the chatRefRect relative to the viewport
        const chatBottom = chatRefRect.bottom + window.scrollY;

        // Bottom of the viewport relative to the document
        const bottomOfScroll =
          window.scrollY + window.innerHeight - bottomOffset;

        // Check if the bottom of the chatRefRect has entered the viewport
        const bottomOfChatHasEnteredViewport = chatBottom <= bottomOfScroll;

        if (bottomOfChatHasEnteredViewport && isSticky) {
          // console.log('The bottom of the chat has entered the viewport.');
          setIsSticky(false);
        } else if (!bottomOfChatHasEnteredViewport && !isSticky) {
          // console.log('The bottom of the chat has not entered the viewport.');
          setIsSticky(true);
        }
      };
      window.addEventListener('scroll', handleScroll);
      window.addEventListener('resize', handleScroll);

      handleScroll();
      return () => {
        window.removeEventListener('scroll', handleScroll);
        window.removeEventListener('resize', handleScroll);
      };
    }, [bottomOffset, isSticky]);

    const handleAiLawyerChange = (aiLawyer: AiLawyer) => {
      setSelectedAiLawyer(aiLawyer);

      if (!prematter?.id) {
        return;
      }

      dispatch(
        actions.prematter.updateAiLawyer({
          id: prematter.id,
          aiLawyer: aiLawyer,
        })
      );
    };

    const handleDropOfFile = useCallback(
      (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        event.stopPropagation();
        const files = Array.from(event.dataTransfer.files);
        if (inputRef.current) {
          inputRef.current.addFiles(files);
        }
        setFileOver(false);
      },
      []
    );

    const handleSendMessage = (args: { text?: string; files?: File[] }) => {
      onSendMessage({ type: MessageType.Chat, ...args });
    };

    const sendConfirmMessage = () => {
      onSendMessage({
        type: MessageType.Confirmation,
        text: t('confirm.continue'),
      });
    };

    useImperativeHandle(ref, () => ({
      setMessage: (message: string) => {
        if (inputRef.current) {
          inputRef.current.setMessage(message);
        }
      },
      scrollToBottom: () => {
        endRef.current?.scrollIntoView({
          behavior: 'smooth',
          block: 'end', // Aligns the element at the bottom of the viewport
          inline: 'nearest', // Aligns the element horizontally if necessary
        });
      },
    }));

    const hideChat = [
      LegalCaseState.PreparingQuestions,
      LegalCaseState.Questions,
      LegalCaseState.PreparingForm,
      LegalCaseState.Form,
    ].includes(prematter?.state);

    const isComplete = [
      LegalCaseState.PreparingReport,
      LegalCaseState.ReportReady,
      LegalCaseState.VerificationPending,
      LegalCaseState.Verified,
    ].includes(prematter?.state);

    // we want to look for these ids ('terms-link' and 'privacy-link') that are conditionally shown in the forms
    useEffect(() => {
      if (prematter?.context?.form?.questions) {
        const termsLinkSpan = document.getElementById('terms-link');
        const privacyLinkSpan = document.getElementById('privacy-link');
        const onPrivacyClick = () => {
          window.open('/privacy', '_blank');
        };
        const onTermsClick = () => {
          window.open('/terms', '_blank');
        };
        if (termsLinkSpan) {
          termsLinkSpan.classList.add(styles.inlineLink);
          termsLinkSpan.addEventListener('click', onTermsClick);
        }
        if (privacyLinkSpan) {
          privacyLinkSpan.classList.add(styles.inlineLink);
          privacyLinkSpan.addEventListener('click', onPrivacyClick);
        }

        return () => {
          termsLinkSpan?.removeEventListener('click', onTermsClick);
          privacyLinkSpan?.removeEventListener('click', onPrivacyClick);
        };
      }
    }, [prematter?.context?.form?.questions, formProgress]);

    const onFormChange = useCallback(() => {
      setFormProgress((a) => a + 1);
    }, []);

    useEffect(() => {
      if (prematter?.language) {
        const asyncWrapper = async () => {
          const wrapped = await addLanguage(prematter.language);
          setWrappedLanguage(wrapped);
        };
        asyncWrapper();
      }
    }, [prematter?.language]);

    return (
      <>
        <div
          {...elementProps}
          className={`${styles.chat} ${props.className || ''}`}
          ref={chatRef}
          onDragOver={
            isComplete || hideChat
              ? undefined
              : (event) => {
                event.preventDefault();
                if (!fileOver) {
                  setFileOver(true);
                }
              }
          }
          style={
            {
              '--chat-system-font-color': '#333333',
              ...props.style,
              marginBottom: hideChat && isMobile ? 
                `${bottomOffset}px` : undefined,
            } as React.CSSProperties
          }
        >
          <div
            className={`${styles.messages} ${readOnly ? styles.readOnly : ''}`}
            style={{
              paddingBottom:
                !isMobile && !isComplete && !hideChat
                  ? `${stickyInputRefBounds.height}px`
                  : undefined,
            }}
          >
            {messages.map((message, index) => {
              if (message.questions && prematter) {
                return (
                  <Fragment key={index}>
                    <div style={{ marginBottom: 10, marginTop: 10 }}>
                      {prematter &&
                      ![
                        LegalCaseState.MatterTypes,
                        LegalCaseState.Location,
                        LegalCaseState.PreparingQuestions,
                        LegalCaseState.AuthCheck,
                      ].includes(prematter.state) ? (
                          <FAQComponent
                            prematter={prematter as Prematter}
                            addQuestion={addQuestion}
                            deleteQuestion={() => {}}
                            addQuestionPending={addQuestionPending}
                            submitFormPending={false}
                            hideChatMessage={true}
                            error={addQuestionError}
                            readOnly={
                              [
                                LegalCaseState.PreparingForm,
                                LegalCaseState.Form,
                                LegalCaseState.ReportReady,
                                LegalCaseState.VerificationPending,
                                LegalCaseState.Verified,
                              ].includes(prematter.state) || disabled
                            }
                          />
                        ) : null}
                    </div>
                    <MessageRenderer
                      type={message.from === 'lawyer' ? 'sender' : 'receiver'}
                      message={message}
                      aiLawyer={selectedAiLawyer}
                      onAiLawyerChange={handleAiLawyerChange}
                      headerContainerRef={headerContainerRef}
                      chatContainerRef={chatRef}
                      showMobileTimestamp={showMobileTimestamps[message.id]}
                      setShowMobileTimestamp={(state: boolean) =>
                        setShowMobileTimestamp(message.id, state)
                      }
                      readOnly={readOnly}
                    >
                      {index === messages.length - 1 &&
                      ![
                        LegalCaseState.PreparingForm,
                        LegalCaseState.Form,
                        LegalCaseState.ReportReady,
                        LegalCaseState.VerificationPending,
                        LegalCaseState.Verified,
                      ].includes(prematter.state) ? (
                          <div className={styles.confirmWrapper}>
                            <div
                              className={styles.button}
                              onClick={sendConfirmMessage}
                            >
                              {t('confirm.continue')} <span>→</span>
                            </div>
                          </div>
                        ) : null}
                    </MessageRenderer>
                  </Fragment>
                );
              }

              return (
                <MessageRenderer
                  type={message.from === 'lawyer' ? 'sender' : 'receiver'}
                  message={message}
                  key={index}
                  aiLawyer={selectedAiLawyer}
                  onAiLawyerChange={handleAiLawyerChange}
                  headerContainerRef={headerContainerRef}
                  chatContainerRef={chatRef}
                  showMobileTimestamp={showMobileTimestamps[message.id]}
                  setShowMobileTimestamp={(state: boolean) =>
                    setShowMobileTimestamp(message.id, state)
                  }
                  readOnly={readOnly}
                >
                  {message?.fileIds?.map((fileId) => {
                    return (
                      <ChatFile
                        key={fileId}
                        file={
                          files.find((f) => f.id === fileId) ||
                          ({} as PrematterFile)
                        }
                      />
                    );
                  })}
                </MessageRenderer>
              );
            })}
            {isFileUploading ? <FileUploading filesLoading={true} /> : null}

            {(isComplete || prematter?.state === LegalCaseState.Form) &&
            prematter.context?.form ? (
                <div className={styles.formWrapper}>
                  <InlineForm
                    title={t('chat.questionnaire')}
                    form={prematter.context.form}
                    onChange={onFormChange}
                    onComplete={onFormCompletion}
                    complete={isComplete}
                    files={files}
                    onFileUpload={onFormFileUpload}
                    language={findBestMatchLanguage(prematter?.language)}
                  // readOnly={disabled}
                  />
                </div>
              ) : null}

            {isLoading && !isFileUploading ? (
              <div className={styles.awaitingReply}>
                <LoadingSvg className={styles.loadingIcon} />
                <div className={styles.awaitingReplyText}>
                  {prematter?.state === LegalCaseState.Questions
                    ? t('chat.loading.form')
                    : t('chat.loading.typing')}
                </div>
              </div>
            ) : null}
            {prematter?.state === LegalCaseState.PreparingForm ? (
              <div className={styles.awaitingReply}>
                <LoadingSvg className={styles.loadingIcon} />
                <div className={styles.awaitingReplyText}>
                  {t('chat.loading.form')}
                </div>
              </div>
            ) : null}
            {isComplete || hideChat ? null : (
              <>
                <div
                  ref={stickyInputRef}
                  className={styles.stickyInputWrapper}
                  style={{
                    position: isSticky ? 'fixed' : 'absolute',
                    display: isComplete || hideChat ? 'none' : 'flex',
                    marginBottom: isSticky ? `${bottomOffset}px` : '0',
                  }}
                >
                  <ChatInput
                    onSendMessage={handleSendMessage}
                    ref={inputRef}
                    className={`${styles.input} ${inputClassName}`}
                    disabled={disabled}
                  />
                </div>
              </>
            )}
            {isComplete || hideChat ? null : fileOver ? (
              <div
                className={styles.dropZoneWrapper}
                onDragOver={(event) => {
                  event.preventDefault();
                }}
                onDragEnter={(event) => {
                  event.preventDefault();
                  setFileOver(true);
                }}
                onDragLeave={() => {
                  setFileOver(false);
                }}
                onDrop={handleDropOfFile}
              >
                <div className={styles.dropZoneBackground} />
                <div className={styles.dropZone}>Drop files anywhere</div>
              </div>
            ) : null}
            {isComplete ? (
              <div className={styles.nextStepCardWrapper}>
                <MessageRenderer
                  type={'sender'}
                  style={styles.nextStepMessage}
                  message={{
                    id: 'complete',
                    type: MessageType.Chat,
                    from: 'lawyer',
                    content: postQuestionnaireMessage,
                    createdAt:
                      prematter?.context?.form?.questions[
                        prematter?.context?.form?.questions.length - 1
                      ].answeredAt ||
                      prematter?.context?.form?.updatedAt ||
                      Date.now(),
                  }}
                  aiLawyer={selectedAiLawyer}
                  onAiLawyerChange={handleAiLawyerChange}
                  headerContainerRef={headerContainerRef}
                  chatContainerRef={chatRef}
                  showMobileTimestamp={showMobileTimestamps['1']}
                  setShowMobileTimestamp={(state: boolean) =>
                    setShowMobileTimestamp('1', state)
                  }
                  readOnly={readOnly}
                >
                  <div className={styles.viewReportCard}>
                    <div className={styles.viewReportCardIcon}>
                      <FolderIcon width={24} height={24} />
                    </div>
                    <div className={styles.viewReportCardHeader}>
                      <div className={styles.viewReportCardTitle}>
                        {prematter?.name}
                      </div>
                      <div className={styles.viewReportCardSubtitle}>
                        {formatDate(prematter.createdAt).toString()}
                      </div>
                    </div>
                    <Button
                      onClick={() => onComplete && onComplete()}
                      label={t('chat.view-answers')}
                      className={styles.viewReportCardButton}
                    />
                  </div>
                </MessageRenderer>
                {/* <div className={styles.nextStepCard}>
                  <NextStepCard
                    image={{
                      src: `/next-steps/report${isMobile ? '-mobile' : ''}.jpg`,
                      icon: <FolderIcon width={24} height={24} />,
                      label: 'Report',
                    }}
                    title={prematter?.name}
                    features={[formatDate(prematter.createdAt).toString()]}
                    button={{
                      label: t('chat.view-answers'),
                      onClick: () => onComplete && onComplete(),
                    }}
                    rounded={true}
                  />
                </div> */}
              </div>
            ) : null}
          </div>
        </div>
        <div
          ref={endRef}
          className={styles.endRef}
          style={{ 
            height: isMobile ? bottomOffset : undefined
          }}
        />
      </>
    );
  }
);
