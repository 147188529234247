/* eslint-disable @stylistic/js/max-len */
import { LibraryCategory } from '../../../../types/library';
import cases from './cases.json';
import bubbleImage from '../images/family-law-bubble.png';
import familyImage from '../images/family.jpg';
import familyImageFull from '../images/family-full.jpg';
import Icon from '../images/family-law.svg?react';

const TITLE = 'Family law';

const familyLaw: LibraryCategory = {
  name: TITLE,
  title: 'Introduction to Family law in Ireland',
  image: familyImage,
  previewImage: familyImageFull,
  shortDescription:
    'Navigating family law in Ireland can be complex and challenging.',
  description:
    'Family law is the area of law that deals with family matters and domestic relations, including marriage, civil unions, and domestic partnership. ',
  cases: cases.filter((c) => c.category.toLocaleLowerCase() === TITLE.toLocaleLowerCase()),
  Icon,
  bubbleImage,
  bodyText: [
    
  ],
};

export default familyLaw;
