import React, { useMemo } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { Header } from '../../../components/header';
import { Footer } from '../../../components/footer';
import { LibraryHero } from '../hero';
import libraryAssets, { listOfLibraryCountries } from '../assets';
import style from './style.module.less';
import { CategoryCards } from '../category-card';
import { NeedHelpCard } from '../../../components/need-help';
import { Link, useParams, useSearchParams } from 'react-router-dom';
import { LibraryCountryCode } from '../../../constants';

export const LibraryHome = () => {
  // get the :country from the url
  const { countryCode } = useParams<{ countryCode: LibraryCountryCode }>();
  // get query params ?show-selector=true
  const [searchParams] = useSearchParams();
  const showSelector = searchParams.get('show-selector') === 'true';

  const { t } = useTranslation();

  const currentCountry = useMemo(
    () =>
      listOfLibraryCountries.find((country) => country.code === countryCode),
    [countryCode]
  );

  return (
    <>
      <Helmet>
        <title>{t('pages.library')}</title>
      </Helmet>
      <Header />
      <div className={style.menuDivider} />
      <LibraryHero
        breadcrumbs={[
          {
            name: 'Library',
          },
        ]}
        description={t('library.home.heading')}
      />
      <div className={style.container}>
        <div className={style.descriptionPadding}>
          {currentCountry ? (
            <>
              <div className={style.descriptionContainer}>
                {t('library.home.description')}
              </div>
              <CategoryCards
                categories={libraryAssets}
                classNames={{ containerClassName: style.categoriesContainer }}
              />
            </>
          ) : (
            <>
              <div className={style.notSupportedContainer}>
                <div
                  className={style.notSupportedText}
                  dangerouslySetInnerHTML={{
                    __html: t('library.country-not-supported'),
                  }}
                />
              </div>
            </>
          )}
        </div>
        {showSelector && (
          <div className={style.countryListContainer}>
            <div className={style.countryListTitle}>
              {t('library.country-selector-title')}
            </div>
            <div
              className={style.countryListDescription}
              dangerouslySetInnerHTML={{
                __html: t('library.country-selector-description'),
              }}
            />

            <div className={style.countryLists}>
              {listOfLibraryCountries
                .filter(
                  (country) => country.language !== currentCountry.language
                )
                .map((country) => (
                  <div className={style.countryList} key={country.country}>
                    <Link
                      to={`/library/${country.code}`}
                      className={style.countryListName}
                    >
                      {country.country}
                    </Link>
                    <div className={style.countryCategories}>
                      {country.content.map((category) => (
                        <Link
                          to={`/library/${country.code}/${category.name}`}
                          className={style.countryCategory}
                          key={category.name}
                        >
                          {category.name}
                        </Link>
                      ))}
                    </div>
                  </div>
                ))}
            </div>
          </div>
        )}
        <div className={style.needHelpPadding}>
          <NeedHelpCard />
        </div>
      </div>
      <Footer className={style.noFooterMargin} />
    </>
  );
};
