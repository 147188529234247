import React, { CSSProperties, FC, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import style from './style.module.less';
import env from '../../../constants/env';
import { toast } from 'react-toastify';
import { Button } from '../../../components/button';
import CopyIcon from '../../../assets/icons/copy-link.svg?react';
import { LawyerImage } from '../lawyer-images';
import AirplaneIcon from '../../../assets/icons/airplane.svg?react';

const EMAIL = `chat@${env.EMAIL_DOMAIN}`;

interface Props {
  lawyerImage: LawyerImage;
}

export const EmailIssuesComponent: FC<Props> = (props) => {
  const { lawyerImage } = props;
  const { t } = useTranslation();

  const navigateToEmail = useCallback(() => {
    window.open(`mailto:${EMAIL}?subject=Legal question`, '_blank');
  }, []);

  const copyLink = useCallback(() => {
    navigator.clipboard.writeText(EMAIL);
    toast.success(t('chat.copied-link'));
  }, [t]);

  return (
    <div className={style.descriptionCard}>
      <div className={style.emailCardImageWrapperDesktop}>
        <AirplaneIcon className={style.emailCardImage} />
      </div>
      <div className={style.bodyContainer}>
        <div className={style.title}>
          <div className={style.emailCardImageWrapperMobile}>
            <AirplaneIcon className={style.emailCardImage} />
          </div>
          {t('home.email-issues')}
        </div>
        <div className={style.emailDescription}>
          {t('home.email-issues-description')}
        </div>
      </div>
      <div className={style.buttonContainer}>
        <Button
          label={t('home.send-email')}
          className={style.emailUsButton}
          onClick={navigateToEmail}
        />
      </div>
    </div>
  );
};
