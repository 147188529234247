/* eslint-disable @stylistic/js/max-len */
import FaceBookIcon from '../assets/icons/social-fb.svg?react';
import InstagramIcon from '../assets/icons/social-instagram.svg?react';
import TikTockIcon from '../assets/icons/social-tik-tok.svg?react';
import { WebSocketMessageType } from '@law-connect/types';
import env from './env';

export const ALLOWED_MESSAGE_TYPES: WebSocketMessageType[] = [
  WebSocketMessageType.Chat,
  WebSocketMessageType.Location,
  WebSocketMessageType.Confirmation,
];

export const WINDOW_REQUEST_RESET_TOKEN = 'reset-token';
export const FALLBACK_LANGUAGE = 'en-au';

export const CONTACT_US_LINK = `mailto:info@${env.PUBLIC_URL.replace(/^https?:\/\//, '')}?subject=Contact Us`;
export const INFO_LINK = `info@${env.PUBLIC_URL.replace(/^https?:\/\//, '')}`;
export const PRIVACY_LINK = `privacy@${env.PUBLIC_URL.replace(/^https?:\/\//, '')}`;
export const PORTAL_CHAT_MOBILE = 'portal-chat-mobile';

export const GOOGLE_PRIVACY_POLICY = 'https://policies.google.com/privacy';
export const GOOGLE_TERMS = 'https://policies.google.com/terms';
export const SHOW_DISCLAIMER_COOKIE = 'show-disclaimer';
export const MAX_ALLOWED_QUESTION = 10;
export const AUTH_MODAL_PORTAL_ID = 'auth-modal-portal';
export const OLD_LAW_CONNECT_LINK = 'https://app.us.lawconnect.com/sign-in';

export const SOCIAL_MEDIA_LINKS = [
  {
    icon: FaceBookIcon,
    link: 'https://www.facebook.com/LawConnectLocal',
  },
  {
    icon: InstagramIcon,
    link: 'https://www.instagram.com/lawconnectau/',
  },
  {
    icon: TikTockIcon,
    link: 'https://www.tiktok.com/@lawconnect_ai?is_from_webapp=1&sender_device=pc',
  },
  // {
  //   icon: XIcon,
  //   link: 'https://x.com/askmaxwellai',
  // }
];

export const CAROUSEL_CARD_SIZES = {
  mobile: 250,
  tablet: 328,
  desktop: 385,
};

export const LIBRARY_DESKTOP_CARD_WIDTH = 345;
export const CHAT_EMAIL = `chat@${env.EMAIL_DOMAIN}`;

export const enum CardWidthSizes {
  Tablet = 492,
  Mobile = 210,
}

export enum LanguageCodes {
  Australia = 'en-au',
  Canada_English = 'en-ca',
  Canada_French = 'fr-ca',
  France = 'fr-fr',
  Ireland = 'en-ie',
  NewZealand = 'en-nz',
  UnitedKingdom = 'en-gb',
  UnitedStates = 'en-us',
}

export enum LibraryCountry {
  Australia = 'Australia',
  Canada = 'Canada',
  France = 'France',
  Ireland = 'Ireland',
  NewZealand = 'New Zealand',
  UnitedKingdom = 'United Kingdom',
  UnitedStates = 'United States',
}


export enum LibraryCountryCode {
  Australia = 'au',
  Canada = 'ca',
  France = 'fr',
  Ireland = 'ie',
  NewZealand = 'nz',
  UnitedKingdom = 'uk',
  UnitedStates = 'us',
}
