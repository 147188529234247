import bnINJSON from './bn-in';
import daDKJSON from './da-dk';
import deDEJSON from './de-de';
import enAUJSON from './en-au';
import enCAJSON from './en-ca';
import enIEJSON from './en-ie';
import enNZJSON from './en-nz';
import enGBJSON from './en-gb';
import enUSJSON from './en-us';
import esESJSON from './es-es';
import esUSJSON from './es-us';
import frFRJSON from './fr-fr';
import frCAJSON from './fr-ca';
import itITJSON from './it-it';
import hiINJSON from './hi-in';
import jaJPJSON from './ja-jp';
import koKRJSON from './ko-kr';
import nlBEJSON from './nl-be';
import nlNLJSON from './nl-nl';
import ptBRJSON from './pt-br';
import zhCNJSON from './zh-cn';
import zhHKJSON from './zh-hk';

export const DEFAULT_LANGUAGE_CODE = 'en-US';

type JSONObject = { translation: { [key: string]: unknown } };

export const languages: {
  [lng: string]: { name: string; nativeName: string; json: JSONObject };
} = {
  // this is the fallback language
  'en-us': {
    name: 'English',
    nativeName: 'USA',
    json: enUSJSON,
  },
  'en-au': {
    name: 'Australia',
    nativeName: 'Australia',
    json: enAUJSON,
  },
  'nl-be': {
    name: 'Belgium (Dutch)',
    nativeName: 'Belgium',
    json: nlBEJSON,
  },
  'pt-br': {
    name: 'Brazil',
    nativeName: 'Brasil',
    json: ptBRJSON,
  },
  'en-ca': {
    name: 'Canada (English)',
    nativeName: 'Canada (English)',
    json: enCAJSON,
  },
  'fr-ca': {
    name: 'Canada (French)',
    nativeName: 'Canada (Français)',
    json: frCAJSON,
  },
  'de-de': {
    name: 'German',
    nativeName: 'Deutschland',
    json: deDEJSON,
  },
  'da-dk': {
    name: 'Danish',
    nativeName: 'Danmark',
    json: daDKJSON,
  },
  'es-us': {
    name: 'United States (Spanish)',
    nativeName: 'USA (Español)',
    json: esUSJSON,
  },
  'es-es': {
    name: 'Spain',
    nativeName: 'España',
    json: esESJSON,
  },
  'fr-fr': {
    name: 'France',
    nativeName: 'France',
    json: frFRJSON,
  },
  'bn-in': {
    name: 'Bengali (India)',
    nativeName: 'India (Bengali)',
    json: bnINJSON,
  },
  'hi-in': {
    name: 'Hindi (India)',
    nativeName: 'India (Hindi)',
    json: hiINJSON,
  },
  'en-ie': {
    name: 'Ireland',
    nativeName: 'Ireland',
    json: enIEJSON,
  },
  'it-it': {
    name: 'Italy',
    nativeName: 'Italia',
    json: itITJSON,
  },
  'nl-nl': {
    name: 'Netherlands',
    nativeName: 'Nederland',
    json: nlNLJSON,
  },
  'en-nz': {
    name: 'New Zealand',
    nativeName: 'New Zealand',
    json: enNZJSON,
  },
  'en-gb': {
    name: 'United Kingdom',
    nativeName: 'United Kingdom',
    json: enGBJSON,
  },
  'ja-jp': {
    name: 'Japan',
    nativeName: '日本',
    json: jaJPJSON,
  },
  'zh-hk': {
    name: 'Hong Kong (Traditional)',
    nativeName: '香港',
    json: zhHKJSON,
  },
  'zh-cn': {
    name: 'China (Simplified)',
    nativeName: '简体中文',
    json: zhCNJSON,
  },
  'ko-kr': {
    name: 'Korea (South)',
    nativeName: '대한민국',
    json: koKRJSON,
  },
};

// language code is language-country
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getTranslation = (key: string, languageCode: string): any => {
  if (!languageCode || !(languageCode in languages)) {
    if (!languageCode.includes('-') && languageCode.length === 2) {
      const findBase = Object.keys(languages).find((lang) =>
        lang.startsWith(languageCode)
      );
      if (findBase) {
        languageCode = findBase;
      } else {
        languageCode = DEFAULT_LANGUAGE_CODE;
      }
    } else {
      const prefix = languageCode.split('-')[0];
      const findBase = Object.keys(languages).find((lang) =>
        lang.startsWith(prefix)
      );
      if (findBase) {
        languageCode = findBase;
      } else {
        languageCode = DEFAULT_LANGUAGE_CODE;
      }
    }
  }

  try {
    const returnValue = key
      .split('.')
      .reduce(
        (current, key) => current && current[key],
        languages[languageCode].json.translation
      );

    if (returnValue) {
      return returnValue;
    }
  } catch (e) {
    console.log('error', e);
    //ignore
  }
  try {
    const returnValue = key
      .split('.')
      .reduce(
        (current, key) => current && current[key],
        languages[DEFAULT_LANGUAGE_CODE].json.translation
      );
    if (returnValue) {
      return returnValue;
    } else {
      return key;
    }
  } catch (e) {
    console.log('error', e);
    return key;
  }
};
