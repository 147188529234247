import React, { FC } from 'react';
import style from './style.module.less';
import { useTranslation } from 'react-i18next';
import mediaWeekLogo from '../../../assets/home/logo-mediaweek.svg?react';
import smartCompanyLogo from '../../../assets/home/logo-smart-company.svg?react';
import theAustralianLogo from '../../../assets/home/logo-the-australian.svg?react';

const MEDIA_DATA = [
  {
    link: 'https://www.smartcompany.com.au/technology/artificial-intelligence/lawconnect-ai-revolutionise-how-aussies-access-legal-help/',
    name: 'Smart Company',
    Icon: smartCompanyLogo,
  },
  {
    link: 'https://www.theaustralian.com.au/business/technology/christian-becks-lawconnect-to-provide-free-legal-advice-to-australians/news-story/1e1f8b0f4ed59557ea2352656d8c9a81',
    name: 'The Australian',
    Icon: theAustralianLogo,
  },
  {
    link: 'https://www.mediaweek.com.au/thrive-house-onboard-lawconnect-superyacht-announced-at-sxsw-sydney/',
    name: 'Mediaweek',
    Icon: mediaWeekLogo,
  },
];

export const MediaComponent: FC = () => {
  const { t } = useTranslation();

  return (
    <div className={style.mediaContainer}>
      <div className={style.mediaHeader}>
        <div className={style.mediaTitle}>{t('home.media')}</div>
      </div>
      <div className={style.mediaContent}>
        {MEDIA_DATA.map((media) => (
          <a
            key={media.name}
            href={media.link}
            className={style.mediaCard}
            target='_blank'
            rel='noreferrer'
          >
            <media.Icon className={style.mediaIcon} />
          </a>
        ))}
      </div>
    </div>
  );
};
