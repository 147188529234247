/* eslint-disable @stylistic/js/max-len */
import { LibraryCategory } from '../../../../types/library';
import cases from './cases.json';
import libraryEmploymentImage from '../images/employment.jpg';
import libraryEmploymentImageFull from '../images/employment-full.jpg';

import Icon from '../images/employment-law.svg?react';
import bubbleImage from '../images/employment-law-bubble.png';

const TITLE = 'Employment and labor law';

const employmentLaw: LibraryCategory = {
  name: TITLE,
  title: 'Introduction to Employment law in Canada',
  image: libraryEmploymentImage,
  previewImage: libraryEmploymentImageFull,
  shortDescription: 'Fair treatment for both employers and employees.',
  description:
    'Employment law in Australia, primarily governed by the Fair Work Act 2009, sets out the rights and obligations of both employees and employers, ensuring fair treatment, workplace safety, and compliance with legal standards.',
  cases: cases.filter((c) => c.category.toLocaleLowerCase() === TITLE.toLocaleLowerCase()),
  Icon,
  bubbleImage,
  bodyText: [
    
  ],
};

export default employmentLaw;
