/* eslint-disable @stylistic/js/max-len */
import { LibraryCategory } from '../../../../types/library';
import cases from './cases.json';
import bubbleImage from '../images/estates-probate-bubble.png';
import estatesImage from '../images/estates.jpg';
import estatesImageFull from '../images/estates-full.jpg';
import Icon from '../images/estates-probate.svg?react';

const TITLE = 'Estate and probate law';

const estatesAndProbates: LibraryCategory = {
  name: TITLE,
  title: 'Introduction to Estates and probates',
  image: estatesImage,
  previewImage: estatesImageFull,
  shortDescription: 'Understanding estate planning and probate processes.',
  description:
    'Estates comprise all valuable possessions owned at the time of an individual\'s death, including bank accounts, property, shares, superannuation funds, and collections like jewellery. Proper estate planning ensures these assets are distributed according to your wishes, not just through a will. <br/><br/> In the United Kingdom, you must be 18 or older for your estate plan to be legally binding. An estate plan can include documents such as a will, superannuation nominations, trusts, life insurance, powers of attorney, guardianship arrangements, and advance care directives. Note that estate legislation varies slightly across the United Kingdom.',
  cases: cases.filter(
    (c) => c.category.toLocaleLowerCase() === TITLE.toLocaleLowerCase()
  ),
  Icon,
  bubbleImage,
  bodyText: [
    
  ],
};

export default estatesAndProbates;
