import { LanguageCodes, LibraryCountry, LibraryCountryCode } from '../../../constants';
import { LibraryCategory } from '../../../types/library';
import australiaContent, {australiaDescription} from './australia';
import canadaContent, {canadaDescription} from './canada';
import franceContent, {franceDescription} from './france';
import irelandContent, {irelandDescription} from './ireland';
import newZealandContent, {newZealandDescription} from './new-zealand';
import ukContent, {ukDescription} from './united-kingdom';
import usContent, {usDescription} from './united-states';

export default australiaContent;

export interface LibraryCountryData {
  country: LibraryCountry;
  language: LanguageCodes;
  code: LibraryCountryCode;
  content:  LibraryCategory[];
  description: string;
}

export const listOfLibraryCountries: LibraryCountryData[] = [
  {
    country: LibraryCountry.Australia,
    language: LanguageCodes.Australia,
    code: LibraryCountryCode.Australia,
    content: australiaContent,
    description: australiaDescription,
  },
  {
    country: LibraryCountry.Canada,
    language: LanguageCodes.Canada_English,
    code: LibraryCountryCode.Canada,
    content: canadaContent,
    description: canadaDescription,
  },
  {
    country: LibraryCountry.France,
    language: LanguageCodes.France,
    code: LibraryCountryCode.France,
    content: franceContent,
    description: franceDescription,
  },
  {
    country: LibraryCountry.Ireland,
    language: LanguageCodes.Ireland,
    code: LibraryCountryCode.Ireland,
    content: irelandContent,
    description: irelandDescription,
  },
  {
    country: LibraryCountry.NewZealand,
    language: LanguageCodes.NewZealand,
    code: LibraryCountryCode.NewZealand,
    content: newZealandContent,
    description: newZealandDescription,
  },
  {
    country: LibraryCountry.UnitedKingdom,
    language: LanguageCodes.UnitedKingdom,
    code: LibraryCountryCode.UnitedKingdom,
    content: ukContent,
    description: ukDescription,
  },
  {
    country: LibraryCountry.UnitedStates,
    language: LanguageCodes.UnitedStates,
    code: LibraryCountryCode.UnitedStates,
    content: usContent,
    description: usDescription,
  },
];
