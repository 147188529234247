import React, {
  ChangeEvent,
  FC,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import style from './style.module.less';
import { usePrevious } from '../../hooks/use-previous';
import { useTranslation } from 'react-i18next';
import { Button } from '../button';
import QuestionComponent from './question';
import PlusCircleSvg from '../../assets/icons/plus-circle.svg?react';
import { LegalCaseState, MessageType, Prematter } from '@law-connect/types';
import ChatMessage from '../chat-history/message';
import { FAQAddQuestion } from '../faq-add-question';
import { MAX_ALLOWED_QUESTION } from '../../constants';
import ChevronDownIcon from '../../assets/icons/chevron-down.svg?react';
import ChevronUpIcon from '../../assets/icons/chevron-up.svg?react';
import { addLanguage, findBestMatchLanguage } from '../../i18n';

interface Props {
  prematter: Prematter;
  hideChatMessage?: boolean;
  readOnly?: boolean;
  addQuestion: (args: { question: string }) => void;
  deleteQuestion: (args: { questionId: string }) => void;
  onConfirm?: () => void;
  error?: string;
  children?: ReactNode;

  addQuestionPending: boolean;
  submitFormPending: boolean;
}

const FAQComponent: FC<Props> = (props) => {
  const {
    prematter,
    hideChatMessage = false,
    addQuestion,
    deleteQuestion,
    onConfirm,
    readOnly,
    error,
    children,
    addQuestionPending,
    submitFormPending,
  } = props;

  const [expanded, setExpanded] = useState(false);
  const [hasTyped, setHasTyped] = useState(true);
  const questions = useMemo(
    () => prematter.context.questions || [],
    [prematter.context.questions]
  );
  const [showAddQuestion, setShowAddQuestion] = useState(false);
  const [newQuestion, setNewQuestion] = useState('');
  const [wrappedLanguage, setWrappedLanguage] = useState<string | undefined>(
    undefined
  );

  const { t } = useTranslation(undefined, {
    lng: wrappedLanguage,
  });

  const faqActive = true;

  const answered = false; // questions?.every((q) => q.answer !== null);
  const hasSomeAnswers = questions?.some((q) => q.answer !== null);
  const prevSubmitPending = usePrevious(submitFormPending);
  const prevAddQuestionPending = usePrevious(addQuestionPending);
  const maxQuestions = useMemo(
    () => questions?.length >= MAX_ALLOWED_QUESTION,
    [questions]
  );
  const pending = useMemo(
    () => addQuestionPending || submitFormPending,
    [addQuestionPending, submitFormPending]
  );

  const onConfirmHandler = useCallback(() => {
    if (!readOnly && faqActive && !pending && onConfirm) {
      // call api to getForm
      onConfirm();
    }
  }, [readOnly, faqActive, onConfirm, pending]);

  const deleteQuestionHandler = useCallback(
    (index: number) => {
      if (!readOnly && faqActive && !pending) {
        const questionToDelete = questions[index];
        deleteQuestion({ questionId: questionToDelete.id });
      }
    },
    [readOnly, faqActive, deleteQuestion, questions, pending]
  );
  const toggleShowAdd = useCallback(() => {
    if (!readOnly && !pending) {
      if (showAddQuestion) {
        setNewQuestion('');
      }
      setShowAddQuestion(!showAddQuestion);
    }
  }, [readOnly, pending, showAddQuestion]);

  const onTextChange = useCallback(
    (e: ChangeEvent<HTMLTextAreaElement>) => {
      if (!readOnly) {
        setNewQuestion(e.target.value);
        setHasTyped(true);
      }
    },
    [readOnly]
  );

  const addQuestionHandler = useCallback(() => {
    if (
      !readOnly &&
      newQuestion.length &&
      !pending &&
      questions.length < MAX_ALLOWED_QUESTION
    ) {
      if (faqActive) {
        addQuestion({ question: newQuestion });
        setHasTyped(false);
      }
    } else {
      setShowAddQuestion(false);
    }
  }, [
    readOnly,
    newQuestion,
    pending,
    questions.length,
    faqActive,
    addQuestion,
  ]);

  // after adding question and no error
  useEffect(() => {
    if (!addQuestionPending && prevAddQuestionPending && !error) {
      setShowAddQuestion(false);
      setNewQuestion('');
    }
  }, [addQuestionPending, prevAddQuestionPending, error]);

  // after submitting the form we want to close the add question component
  useEffect(() => {
    if (submitFormPending && !prevSubmitPending) {
      setShowAddQuestion(false);
      setNewQuestion('');
    }
  }, [submitFormPending, prevSubmitPending]);

  useEffect(() => {
    if (prematter?.language) {
      const asyncWrapper = async () => {
        const wrapped = await addLanguage(prematter.language);
        setWrappedLanguage(wrapped);
      };
      asyncWrapper();
    }
  }, [prematter?.language]);

  return (
    <>
      <div
        className={`${
          answered ? style.answeredContainer : style.questionContainer
        }`}
      >
        <div
          // eslint-disable-next-line @stylistic/js/max-len
          className={`${style.questions} ${hasSomeAnswers ? style.noShadow : ''} ${expanded ? style.expanded : ''}`}
        >
          {questions.map((q, i) => (
            <QuestionComponent
              key={q.id || i}
              question={q}
              answered={answered}
              deleteQuestionHandler={() => deleteQuestionHandler(i)}
              readOnly={true} // Set to true to prevent deletion of questions
            />
          ))}
          <FAQAddQuestion
            disabled={hasTyped}
            pending={pending}
            question={newQuestion}
            show={showAddQuestion && !readOnly}
            onChange={onTextChange}
            submit={addQuestionHandler}
            error={error}
            errorClassName={style.error}
            className={style.addQuestionContainer}
            language={findBestMatchLanguage(prematter?.language)}
          />
          {!readOnly && !answered && questions?.length > 0 ? (
            !maxQuestions ? (
              <div
                className={`${style.question} ${style.addQuestion} ${
                  maxQuestions ? style.disabled : ''
                }`}
                onClick={toggleShowAdd}
              >
                {showAddQuestion ? (
                  <>
                    <div className={style.cancelText}>
                      {t('confirm.cancel')}
                    </div>
                  </>
                ) : (
                  <>
                    <div className={style.questionText}>
                      {t('chat.add-own-question')}
                    </div>
                    <button className={style.iconContainer}>
                      <PlusCircleSvg className={style.icon} />
                    </button>
                  </>
                )}
              </div>
            ) : (
              <div className={`${style.question} ${style.maxQuestion}`}>
                {t('prematter.max-questions')}
              </div>
            )
          ) : null}
        </div>
        {!expanded ? (
          <div className={`${style.seeMore} ${expanded ? style.expanded : ''}`}>
            <div
              className={style.seeMoreText}
              onClick={() => setExpanded(!expanded)}
            >
              {expanded ? (
                <>
                  {t('chat.see-less')} <ChevronUpIcon fill={'#0E5FE3'} />
                </>
              ) : (
                <>
                  {t('chat.see-more')} <ChevronDownIcon fill={'#0E5FE3'} />
                </>
              )}
            </div>
          </div>
        ) : null}
      </div>
      {children}
      {!hideChatMessage && !readOnly && !answered && !pending && (
        <div className={style.chatActionContainer}>
          <Button
            onClick={onConfirmHandler}
            label={t('confirm.continue')}
            className={style.chatButton}
            disabled={pending}
          />
        </div>
      )}
      {((prematter?.state === LegalCaseState.Questions && !readOnly) ||
          true) && !answered && submitFormPending && (
        <ChatMessage
          chatState={prematter?.state}
          chat={{
            id: 'confirm.continue',
            content: t('confirm.continue'),
            from: 'client',
            type: MessageType.Confirmation,
            createdAt:
              prematter.messages[prematter.messages.length - 1].createdAt,
          }}
          prematter={prematter}
        />
      )}
    </>
  );
};

export default FAQComponent;
