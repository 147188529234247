import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import style from './style.module.less';
import ArrowRightIcon from '../../../assets/icons/arrow-right.svg?react';
import { StartCaseButton } from '../../../components/need-help';

interface Props {

}

export const GetStartedComponent: FC<Props> = (props) => {

  const { t } = useTranslation();

  return (
    <div className={style.getStartedContainer}>
      <div className={style.getStartedTitle}>
        {t('home.how-it-works.get-started-title')}
      </div>
      <div className={style.getStartedButtonContainer}>
        <StartCaseButton 
          classNames={{
            buttonWrapper: style.getStartedButton,
          }}
        />
      </div>
    </div>
  );
};
