import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { FALLBACK_LANGUAGE } from './constants';

// e.g. ['about.json', 'prematter.json', ...]
let LANGUAGE_FILES: string[] = [];

if (Array.isArray(process.env.TRANSLATION_FILE_NAMES)) {
  LANGUAGE_FILES = process.env.TRANSLATION_FILE_NAMES;
} else {
  try {
    LANGUAGE_FILES = JSON.parse(process.env.TRANSLATION_FILE_NAMES);
  } catch (e) {
    console.error('Error parsing TRANSLATION_FILE_NAMES', e);
  }
}

type JSONObject = { [key: string]: unknown };

export const languages: {
  [lng: string]: { name: string; nativeName: string; json?: JSONObject };
} = {
  'en-au': {
    name: 'Australia',
    nativeName: 'Australia',
    // json: enAUJSON,
  },
  'nl-be': {
    name: 'Belgium (Dutch)',
    nativeName: 'Belgium',
    // json: nlBEJSON,
  },
  'pt-br': {
    name: 'Brazil',
    nativeName: 'Brasil',
    // json: ptBRJSON,
  },
  'en-ca': {
    name: 'Canada (English)',
    nativeName: 'Canada (English)',
    // json: enCAJSON,
  },
  'fr-ca': {
    name: 'Canada (French)',
    nativeName: 'Canada (Français)',
    // json: frCAJSON,
  },
  'de-de': {
    name: 'German',
    nativeName: 'Deutschland',
    // json: deDEJSON,
  },
  'da-dk': {
    name: 'Danish',
    nativeName: 'Danmark',
    // json: daDKJSON,
  },
  'es-us': {
    name: 'United States (Spanish)',
    nativeName: 'USA (Español)',
    // json: esUSJSON,
  },
  'es-es': {
    name: 'Spain',
    nativeName: 'España',
    // json: esESJSON,
  },
  'fr-fr': {
    name: 'France',
    nativeName: 'France',
    // json: frFRJSON,
  },
  'bn-in': {
    name: 'Bengali (India)',
    nativeName: 'India (Bengali)',
    // json: bnINJSON,
  },
  'hi-in': {
    name: 'Hindi (India)',
    nativeName: 'India (Hindi)',
    // json: hiINJSON,
  },
  'en-ie': {
    name: 'Ireland',
    nativeName: 'Ireland',
    // json: enIEJSON,
  },
  'it-it': {
    name: 'Italy',
    nativeName: 'Italia',
    // json: itITJSON,
  },
  'nl-nl': {
    name: 'Netherlands',
    nativeName: 'Nederland',
    // json: nlNLJSON,
  },
  'en-nz': {
    name: 'New Zealand',
    nativeName: 'New Zealand',
    // json: enNZJSON,
  },
  'en-gb': {
    name: 'United Kingdom',
    nativeName: 'United Kingdom',
    // json: enGBJSON,
  },
  'en-us': {
    name: 'English',
    nativeName: 'USA',
    // json: enUSJSON,
  },
  'ja-jp': {
    name: 'Japan',
    nativeName: '日本',
    // json: jaJPJSON,
  },
  'zh-hk': {
    name: 'Hong Kong (Traditional)',
    nativeName: '香港',
    // json: zhHKJSON,
  },
  'zh-cn': {
    name: 'China (Simplified)',
    nativeName: '简体中文',
    // json: zhCNJSON,
  },
  'ko-kr': {
    name: 'Korea (South)',
    nativeName: '대한민국',
    // json: koKRJSON,
  },
};

export const findBestMatchLanguage = (code: string) => {
  if (!code) return undefined;
  const wrappedCode = code.toLowerCase();
  const bestMatchLanguage = Object.keys(languages)
    .sort()
    .find((lang) => lang.toLowerCase().startsWith(wrappedCode));
  return bestMatchLanguage;
};

// language is value from the env
export const addLanguage = async (code: string) => {
  let wrappedCode = code.toLowerCase();
  if (!languages[wrappedCode]) {
    if (code.length === 2) {
      // use browser language
      wrappedCode = navigator.language.toLowerCase();
    } else {
      const bestMatchLanguage = findBestMatchLanguage(wrappedCode);
      if (bestMatchLanguage) {
        wrappedCode = bestMatchLanguage;
      }
    }
  }
  if (languages[wrappedCode] && !languages[wrappedCode].json) {
    const language: { [key: string]: JSONObject } = {};
    // for each language filename, fetch the .json file and add it to the languages object

    await Promise.all(
      LANGUAGE_FILES.map(async (filename) => {
        // eslint-disable-next-line @stylistic/js/max-len
        const response = await fetch(
          `/translations/locales/${wrappedCode}/${filename}`
        );
        language[filename.replace('.json', '')] = await response.json();
      })
    );

    languages[wrappedCode].json = language;
    i18n.addResourceBundle(wrappedCode, 'translation', language);
  }
  return wrappedCode;
};

const resources = Object.keys(languages).reduce(
  (acc: { [lng: string]: JSONObject }, lang: string) => {
    acc[lang] = languages[lang].json;
    return acc;
  },
  {}
);

export const SupportedLanguages = Object.keys(resources).map((key) => ({
  key,
  name: languages[key].name,
  nativeName: languages[key].nativeName,
}));

i18n.on('languageChanged', (lng) => {
  addLanguage(lng);
});

export const setLanguage = async (lng: string) => {
  let targetLanguage =
    lng.length === 2
      ? navigator?.language?.toLowerCase() ?? FALLBACK_LANGUAGE
      : lng.toLowerCase();

  if (!languages[targetLanguage]) {
    const bestMatchLanguage = findBestMatchLanguage(targetLanguage);
    if (bestMatchLanguage) {
      targetLanguage = bestMatchLanguage;
    } else {
      targetLanguage = FALLBACK_LANGUAGE;
    }
  }
  await addLanguage(targetLanguage);
  await i18n.changeLanguage(targetLanguage);
};

// Load translations asynchronously and initialize i18n
i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    debug: process.env.NODE_ENV === 'development',
    lowerCaseLng: true,
  });
