import React, { FC, useEffect, useMemo } from 'react';
import { Navigate, Route, Routes, useNavigate } from 'react-router-dom';
import { LibraryCase } from './case';
import { LibraryHome } from './home';
import { LibraryCategory } from './category';
import { useTranslation } from 'react-i18next';
import { listOfLibraryCountries } from './assets';

// we want to import a list of folders to get the categories

export const Library: FC = () => {
  // if language is changed here, we want to redirect to home page

  const { i18n } = useTranslation();
  const navigate = useNavigate();

  const country = useMemo(
    () =>
      listOfLibraryCountries.find(
        (country) => country.language === i18n.language
      ) ?? listOfLibraryCountries[0],
    [i18n.language]
  );
  // Returns "US", "FR", etc.

  useEffect(() => {
    const handleLanguageChange = (lan: string) => {
      if (!lan.includes('end')) {
        navigate('/');
      }
    };
    i18n.on('languageChanged', handleLanguageChange);
    return () => {
      i18n.off('languageChanged', handleLanguageChange);
    };
  }, [i18n, navigate]);

  return (
    <Routes>
      <Route path='/:countryCode' element={<LibraryHome />} />
      <Route path='/:countryCode/:category' element={<LibraryCategory />} />
      <Route
        path='/:countryCode/:category/:caseName'
        element={<LibraryCase />}
      />

      {/* no match then we redirect to language country */}
      <Route path='*' element={<Navigate to={`/library/${country.code}`} />} />
    </Routes>
  );
};
