import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import YouTube from 'react-youtube';

import { Header } from '../../components/header';
import { useScreenSize, WidthType } from '../../hooks/use-is-mobile';
import HomeBGDesktop from '../../assets/home-bg-desktop.jpg';
import HomeBGMobile from '../../assets/home-bg-mobile.jpg';
import SailingLogo from '../../assets/sailing/sailing-header-logo.png';
import SailingBGImage1 from '../../assets/sailing/sailing-body1.jpg';
import SailingBGImage2 from '../../assets/sailing/sailing-body2.jpg';
import WreathIcon from '../../assets/icons/wreath.svg?react';
import ArrowDownIcon from '../../assets/icons/arrow-down.svg?react';

import style from './style.module.less';
import { Wrapper } from '../../components/wrapper';
import { Footer } from '../../components/footer';
import { NeedHelpCard } from '../../components/need-help';
import { StackedCardComponent } from '../../components/stacked-card';

const LIVE_YOUTUBE_ID = 'bVha4mIPp24';
const NEXT_LIVE_ID = 'ipTaUFsvrL8';
const PLAYLIST_ID = 'PL-Y0_sMgjCae4Fe7p72p7SsGAQQQwcmj6';
const YOUTUBE_CHANNEL = 'https://www.youtube.com/@lawconnectau';

export const SailingPage = () => {
  const { t } = useTranslation();
  const screenType = useScreenSize();
  const Image = screenType === WidthType.Mobile ? HomeBGMobile : HomeBGDesktop;
  const livestreamRef = useRef<HTMLDivElement>(null);

  const [videoError, setVideoError] = useState(true);

  const getVideoWidth = useCallback(() => {
    // so we want 100vw with 80px padding on each side for desktop, 40px for tablet and 20px for mobile

    if (
      screenType === WidthType.LargeDesktop ||
      screenType === WidthType.SmallDesktop
    ) {
      // max width is 1760px
      return Math.min(window.innerWidth - 160, 1760);
    } else if (screenType === WidthType.Tablet) {
      return window.innerWidth - 80;
    } else {
      return window.innerWidth - 40;
    }
  }, [screenType]);

  const [videoWidth, setVideoWidth] = useState(getVideoWidth());

  const onLoadError = () => setVideoError(true);
  const setVideoReady = () => setVideoError(false);

  const scrollToLivestream = useCallback(() => {
    if (livestreamRef.current) {
      window.scrollTo({
        top: livestreamRef.current.offsetTop,
        behavior: 'smooth',
      });
    }
  }, [livestreamRef]);

  useEffect(() => {
    const onResize = () => {
      setVideoWidth(getVideoWidth());
    };

    window.addEventListener('resize', onResize);
    return () => window.removeEventListener('resize', onResize);
  }, [getVideoWidth]);

  return (
    <>
      <Helmet>
        <title>{t('pages.sailing')}</title>
      </Helmet>
      <Header />
      <Wrapper className={style.container} contentClassName={style.content}>
        <div className={style.heroCard}>
          <div className={style.titleWrapper}>
            <div className={style.title}>The LawConnect supermaxi</div>
          </div>
          <div className={style.heroImageWrapper}>
            <img src={Image} alt='scale' className={style.heroImage} />
          </div>
          <div className={style.sailingLogoContainer}>
            <img src={SailingLogo} className={style.sailingLogo} />
          </div>
        </div>
        <div className={style.headerDescriptionContainer}>
          <div
            className={style.headerDescription}
            dangerouslySetInnerHTML={{
              __html: t('sailing.header-description'),
            }}
          />

          {/* anchor to bottom livestream video */}
          <a className={style.watchLink} onClick={scrollToLivestream}>
            <span>{t('sailing.watch-live')}</span>
            <div className={style.watchArrowIconContainer}>
              <ArrowDownIcon className={style.watchArrowIcon} />
            </div>
          </a>
        </div>
        <div className={style.headerCardContainer}>
          <StackedCardComponent
            animationOnScroll={true}
            cardImage={SailingBGImage1}
            RightCard={
              <div className={style.sailingBG1Container}>
                <WreathIcon className={style.sailingBG1Icon} />
                <div className={style.sailingBG1Description}>
                  {t('sailing.sailing-bg1-description')}
                </div>
              </div>
            }
            classNames={{
              textContainer: style.sailingBG1TextContainer,
            }}
          />
        </div>
        <div className={style.headerDescriptionContainer}>
          <div className={style.headerTitle}>
            {t('sailing.lawconnect-live')}
          </div>
          <div
            className={style.headerDescription}
            dangerouslySetInnerHTML={{
              __html: t('sailing.lawconnect-live-description').replace(
                'YOUTUBE_CHANNEL',
                YOUTUBE_CHANNEL
              ),
            }}
          />
        </div>

        {/* livestream goes here */}

        <div className={style.livestreamContainer} ref={livestreamRef}>
          <img
            src={SailingBGImage2}
            alt='sailing'
            className={`${style.livestreamPlaceholder} ${
              videoError || !LIVE_YOUTUBE_ID.length ? style.visible : ''
            }`}
          />

          <YouTube
            // eslint-disable-next-line @stylistic/js/max-len
            className={`${style.livestream} ${videoError || !LIVE_YOUTUBE_ID.length ? style.hidden : ''}`}
            videoId={
              Date.now() > 1735291800000 ? NEXT_LIVE_ID : LIVE_YOUTUBE_ID
            }
            opts={{
              width: videoWidth,
              height: videoWidth * 0.5625,
              playerVars: {
                autoplay: 1,
                controls: 1,
                modestbranding: 1,
                rel: 0,
                showinfo: 0,
                loop: 1,
                playsinline: 1,
                fs: 1,
                disablekb: 1,
                iv_load_policy: 3,
                // listType: 'playlist',
                // list: PLAYLIST_ID,
              },
            }}
            style={{
              width: '100%',
            }}
            onError={onLoadError}
            onReady={setVideoReady}
          />
        </div>
        <div className={style.headerDescriptionContainer}>
          <div
            className={style.headerDescription}
            dangerouslySetInnerHTML={{
              __html: t('sailing.sailing-description2'),
            }}
          />
        </div>

        <NeedHelpCard
          backgroundColour='#F0EFEC'
          buttonColour='#0E5FE3'
          lightMode={true}
          classNames={{
            buttonWrapper: style.needHelpButtonWrapper,
            buttonArrow: style.needHelpButtonArrow,
            buttonArrowIcon: style.needHelpButtonArrowIcon,
          }}
        />
      </Wrapper>
      <Footer className={style.noMargin} />
    </>
  );
};
