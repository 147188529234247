import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { actions } from '.';
import {
  LegalCaseState,
  Message,
  Prematter,
  WebSocketMessage,
  WebSocketMessageType,
} from '@law-connect/types';
import { SessionPrematter } from '../../types/session-prematter';

interface SessionState {
  sessionId: string | null;
  prematter?: Prematter | SessionPrematter | undefined;
  tempMessage?: string;
  waitingForServer?: boolean;
  pending: {
    fetch?: boolean;
    delete?: boolean;
    addQuestion?: boolean;
    deleteQuestion?: boolean;
    getForm?: boolean;
    sendForm?: boolean;
    process?: boolean;
    location?: boolean;
    updateExtendedForm?: boolean;
    sendMessage?: boolean;
    upload?: boolean;
  };
  errors: {
    general?: string | null;
    fetch?: string | null;
    delete?: string | null;
    addQuestion?: string | null;
    deleteQuestion?: string | null;
    getForm?: string | null;
    sendForm?: string | null;
    updateExtendedForm?: string | null;
    process?: string | null;
    sendMessage?: string | null;
    upload?: string | null;
  };
}

const sessionState: SessionState = {
  pending: {},
  errors: {},
  sessionId: null,
};

const sessionSlice = createSlice({
  name: 'session',
  initialState: sessionState,
  // you cant have a reducer name match the thunk action
  reducers: {
    reset: () => {
      return sessionState;
    },
    setWaitingForServer: (state) => {
      state.waitingForServer = true;
    },
    ready: (state) => {
      state.waitingForServer = false;
    },
    updateState: (state, action) => {
      if (!state.prematter) {
        state.errors.general = 'prematter not found';
        return;
      }
      state.prematter.state = action.payload;
    },
    addMessage: (state, action: PayloadAction<WebSocketMessage>) => {
      if (!state.prematter) {
        state.errors.general = 'prematter not found';
        return;
      }
      if (
        state.waitingForServer &&
        (action.payload.type === WebSocketMessageType.Chat ||
          action.payload.type === WebSocketMessageType.Location ||
          action.payload.type === WebSocketMessageType.Confirmation)
      ) {
        state.waitingForServer = false;
      }
      (state.prematter as SessionPrematter).messages.push(action.payload);
    },
    addTempMessage: (state, action: PayloadAction<string>) => {
      state.tempMessage = action.payload;
    },
    clearTempMessage: (state) => {
      state.tempMessage = undefined;
    },
    updateLocation: (state, action) => {
      if (!state.prematter) {
        state.errors.general = 'prematter not found';
        return;
      }
      state.prematter.context.location = action.payload;
      state.prematter.state = LegalCaseState.Questions;
    },
    setLocationPending: (state, action: PayloadAction<boolean>) => {
      state.pending.location = action.payload;
    },
    setContext: (state, action: PayloadAction<Prematter['context']>) => {
      if (!state.prematter) {
        state.errors.general = 'prematter not found';
        return;
      }
      state.prematter.context = action.payload;
    },
    setQuestions: (
      state,
      action: PayloadAction<Prematter['context']['questions']>
    ) => {
      if (!state.prematter) {
        state.errors.general = 'prematter not found';
        return;
      }
      state.prematter.context.questions = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(actions.session.fetch.pending, (state) => {
      state.pending.fetch = true;
      state.errors.fetch = null;
    });
    builder.addCase(actions.session.fetch.fulfilled, (state, action) => {
      state.pending.fetch = false;
      state.errors.fetch = null;
      state.sessionId = action.payload.sessionId;
      if (action.payload.prematter) {
        state.prematter = action.payload.prematter;
      } else {
        state.prematter = {
          id: null,
          language: undefined,
          context: {
            name: '',
            form: null,
            location: null,
            questions: [],
            matterTypes: [],
          },
          reportCompletionPercentage: 0,
          messages: [],
          createdAt: Date.now(),
          state: LegalCaseState.MatterTypes,
          updatedAt: Date.now(),
        };
      }
    });
    builder.addCase(actions.session.fetch.rejected, (state, action) => {
      state.pending.fetch = false;
      state.errors.fetch = (action.payload as string) ?? 'unknown error';
    });
    builder.addCase(actions.session.delete.pending, (state) => {
      state.pending.delete = true;
      state.errors.delete = null;
      state.sessionId = null; // we force the session id to null to prevent any further requests aka websocket
    });
    builder.addCase(actions.session.delete.fulfilled, (state, action) => {
      state.pending = {};
      // on session delete we want to reset the errors
      state.errors = {};
      state.waitingForServer = false;
      state.sessionId = action.payload.sessionId;
      // we also want to cancel all exisiting session sendMessage requests

      if (action.payload.prematter) {
        state.prematter = action.payload.prematter;
      } else {
        state.prematter = {
          id: null,
          language: undefined,
          context: {
            name: '',
            form: null,
            location: null,
            questions: [],
            matterTypes: [],
          },
          reportCompletionPercentage: 0,
          messages: [],
          createdAt: Date.now(),
          state: LegalCaseState.MatterTypes,
          updatedAt: Date.now(),
        };
      }
    });
    builder.addCase(actions.session.delete.rejected, (state, action) => {
      state.pending.delete = false;
      state.errors.delete = (action.payload as string) ?? 'unknown error';
    });

    builder.addCase(actions.prematter.delete.fulfilled, (state, action) => {
      state.pending = {};
      state.errors = {};
      // Ensure that the session prematter is delete if the matter is deleted
      if (state.prematter?.id === action.payload.id) {
        state.prematter = undefined;
      }
    });

    builder.addCase(actions.session.sendMessage.pending, (state, action) => {
      state.pending.sendMessage = true;
      state.errors.sendMessage = null;
      state.prematter.messages.push({
        id: 'new-message',
        type: action.meta.arg.type,
        from: 'client',
        content: action.meta.arg.text,
        createdAt: Date.now(),
      } as Message);
      if (action.meta.arg.files && action.meta.arg.files.length > 0) {
        state.pending.upload = true;
      }
    });
    builder.addCase(actions.session.sendMessage.fulfilled, (state, action) => {
      state.pending.sendMessage = false;
      state.errors.sendMessage = null;
      state.prematter = action.payload;
      state.pending.upload = false;
    });
    builder.addCase(actions.session.sendMessage.rejected, (state, action) => {
      state.pending.sendMessage = false;
      state.errors.sendMessage = (action.payload as string) ?? 'unknown error';
      if (state.pending.upload) {
        state.pending.upload = false;
      }
      // Remove the message from the messages array
      state.prematter.messages = state.prematter.messages.filter(
        (m) => m.id !== 'new-message'
      );
    });

    builder.addCase(actions.session.addQuestion.pending, (state) => {
      state.pending.addQuestion = true;
      state.errors.addQuestion = null;
    });
    builder.addCase(actions.session.addQuestion.fulfilled, (state, action) => {
      state.pending.addQuestion = false;
      state.errors.addQuestion = null;
      state.prematter = action.payload;
      // this shd handle adding the faq to the store once apis are implemented
    });
    builder.addCase(actions.session.addQuestion.rejected, (state, action) => {
      state.pending.addQuestion = false;
      state.errors.addQuestion = (action.payload as string) ?? 'unknown error';
    });
    builder.addCase(actions.session.deleteQuestion.pending, (state) => {
      state.pending.deleteQuestion = true;
      state.errors.deleteQuestion = null;
    });
    builder.addCase(
      actions.session.deleteQuestion.fulfilled,
      (state, action) => {
        state.pending.deleteQuestion = false;
        state.errors.deleteQuestion = null;
        state.prematter.context.questions =
          state.prematter.context.questions.filter(
            (q) => q.id !== action.payload.questionId
          );
      }
    );
    builder.addCase(
      actions.session.deleteQuestion.rejected,
      (state, action) => {
        state.pending.deleteQuestion = false;
        state.errors.deleteQuestion =
          (action.payload as string) ?? 'unknown error';
      }
    );
    builder.addCase(actions.session.getForm.pending, (state) => {
      state.pending.getForm = true;
      state.errors.getForm = null;
    });
    builder.addCase(actions.session.getForm.fulfilled, (state, action) => {
      state.pending.getForm = false;
      state.errors.getForm = null;
      state.prematter = action.payload;
    });
    builder.addCase(actions.session.getForm.rejected, (state, action) => {
      state.pending.getForm = false;
      state.errors.getForm = (action.payload as string) ?? 'unknown error';
    });
    builder.addCase(actions.session.sendForm.pending, (state) => {
      state.pending.sendForm = true;
      state.errors.sendForm = null;
    });
    builder.addCase(actions.session.sendForm.fulfilled, (state, action) => {
      state.pending.sendForm = false;
      state.errors.sendForm = null;
      state.prematter = action.payload;
    });
    builder.addCase(actions.session.sendForm.rejected, (state, action) => {
      state.pending.sendForm = false;
      state.errors.sendForm = (action.payload as string) ?? 'unknown error';
    }),
    builder.addCase(actions.session.process.pending, (state) => {
      state.pending.process = true;
      state.errors.process = null;
    });
    builder.addCase(actions.session.process.fulfilled, (state, action) => {
      state.pending.process = false;
      state.errors.process = null;
    });
    builder.addCase(actions.session.process.rejected, (state, action) => {
      state.pending.process = false;
      state.errors.process = (action.payload as string) ?? 'unknown error';
    });

    builder.addCase(
      actions.session.updateExtendedForm.pending,
      (state, action) => {
        state.pending.updateExtendedForm = true;
        state.errors.updateExtendedForm = null;
      }
    );
    builder.addCase(
      actions.session.updateExtendedForm.fulfilled,
      (state, action) => {
        state.pending.updateExtendedForm = false;
        state.errors.updateExtendedForm = null;
        state.prematter = action.payload;
      }
    );
    builder.addCase(
      actions.session.updateExtendedForm.rejected,
      (state, action) => {
        state.pending.updateExtendedForm = false;
        state.errors.updateExtendedForm =
          (action.payload as string) ?? 'unknown error';
      }
    );

    builder.addCase(actions.session.setPrematter.fulfilled, (state, action) => {
      state.prematter = action.payload;
    });
  },
});

// im not sure if this is good practice but it mimics what we used to do with dispatching actions
export const sessionActions = sessionSlice.actions;
export const sessionReducer = sessionSlice.reducer;
