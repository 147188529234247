import React, {
  FC,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { LibraryCase } from '../../../types/library';
import style from './style.module.less';
import { Carousel, CarouselRef } from '@law-connect/react-components';
import { useScreenSize, WidthType } from '../../../hooks/use-is-mobile';
import {
  CAROUSEL_CARD_SIZES,
  LIBRARY_DESKTOP_CARD_WIDTH,
} from '../../../constants';
import { Card, CardPlaceholder } from '../../../components/card';
import FolderIcon from '../../../assets/icons/folder.svg?react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { convertToKebabCase } from '../../../utils/case-conversion';
import { getLibraryPathWithCountry } from '../../../utils/get-library-path-with-country';

interface Props {
  title: string;
  cases: LibraryCase[];
  className?: string;
  titleClassName?: string;
}

export const LibraryCarousel: FC<Props> = ({
  title,
  cases,
  className,
  titleClassName,
}) => {
  const screenType = useScreenSize();
  const { t } = useTranslation();
  const navigate = useNavigate();
  // placeholder cards for carousel
  const [expectedCards, setExpectedCards] = useState<number | null>(0);
  const cardsScrollRef = useRef<CarouselRef>(null);

  const cardWidth = useMemo(() => {
    if (screenType === WidthType.Mobile) {
      return CAROUSEL_CARD_SIZES.mobile;
    } else if (screenType === WidthType.Tablet) {
      return CAROUSEL_CARD_SIZES.tablet;
    } else {
      return LIBRARY_DESKTOP_CARD_WIDTH;
    }
  }, [screenType]);

  const handleCardResize = useCallback(() => {
    const getExpectedCards =
      cardsScrollRef.current?.getExpectedCards(cardWidth);
    if (getExpectedCards != null) {
      setExpectedCards(getExpectedCards);
    }
  }, [cardWidth]);

  useEffect(() => {
    handleCardResize();
    window.addEventListener('resize', handleCardResize);
    return () => window.removeEventListener('resize', handleCardResize);
  }, [handleCardResize]);

  const cardBody = useMemo(() => {
    const placeholderCards = expectedCards - cases.length;
    const results = cases?.map((c, index) => (
      <Card
        id={index}
        key={`${index}-${c.name}`}
        icon={
          <div className={style.iconContainer}>
            <FolderIcon className={style.briefcaseIcon} />
          </div>
        }
        title={c.name}
        description={t('library.case-study')}
        buttonLabel={t('account.view')}
        onClick={() =>
          navigate(
            getLibraryPathWithCountry(
              `/${convertToKebabCase(c.category)}/${convertToKebabCase(c.name)}`
            )
          )
        }
        fullClick={true}
      />
    ));
    if (placeholderCards > 0) {
      Array.from({ length: placeholderCards }, (_, index) => {
        results.push(
          <CardPlaceholder
            key={index}
            className={style.placeholderCard}
            placeholder
          />
        );
      });
    }

    return results;
  }, [cases, expectedCards, navigate, t]);

  return (
    <div
      className={`${style.carouselContainer} ${className ?? ''}`}
      key={title}
    >
      <div className={`${style.title} ${titleClassName ?? ''}`}>{title}</div>
      <div className={style.carouselWrapper}>
        <Carousel
          ref={cardsScrollRef}
          cardWidth={cardWidth}
          showProgressBar={expectedCards <= cases.length}
          items={cardBody}
          classNames={{
            cardsContainer: `${style.cardsContainer} ${
              expectedCards > cases.length ? style.noCases : ''
            }`,
            cardsPosition: style.cardsPosition,
            actionBar: style.actionBar,
            arrowButtonContainer: style.arrowButtonContainer,
          }}
        />
      </div>
    </div>
  );
};
