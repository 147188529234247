import { createAsyncThunk } from '@reduxjs/toolkit';
import api from '../../api';
import { actions } from '..';
import {
  ExtendedForm,
  IntroductoryForm,
  MessageType,
  Prematter,
} from '@law-connect/types';
import selectors from '../../selectors';
import { RootState } from '../../store';
import { toast } from 'react-toastify';
import i18n from 'i18next';

const STORE = 'session';
const sessionThunk = {
  fetch: createAsyncThunk(`${STORE}/fetch`, async (_, { rejectWithValue }) => {
    try {
      const response = await api.session.fetch();
      return response;
    } catch (error) {
      console.error('fetch session', error);
      return rejectWithValue(error.message as string);
    }
  }),
  setPrematter: createAsyncThunk(
    `${STORE}/setPrematter`,
    async (props: { prematter: Prematter }, { rejectWithValue }) => {
      if (props.prematter && props.prematter.id) {
        try {
          const response = await api.session.setPrematter({
            prematterId: props.prematter?.id,
          });
          return response.prematter;
        } catch (error) {
          console.error('set prematter', error);
          return rejectWithValue(error.message as string);
        }
      } else {
        return props.prematter;
      }
    }
  ),
  delete: createAsyncThunk(
    `${STORE}/delete`,
    async (_, { rejectWithValue, dispatch }) => {
      try {
        await api.session.delete();
        dispatch(actions.websocket.deleteSession());
        const response = await api.session.fetch();
        return response;
      } catch (error) {
        console.error('delete session', error);
        return rejectWithValue(error.message as string);
      }
    }
  ),
  sendMessage: createAsyncThunk(
    `${STORE}/sendMessage`,
    async (
      props: { type: MessageType; text?: string; files?: File[] },
      { rejectWithValue, getState }
    ) => {
      try {
        const state = getState() as RootState;
        const prematterId = selectors.session.getPrematterId()(state);
        const response = await api.session.sendMessage({
          prematterId,
          type: props.type,
          text: props.text,
          files: props.files,
          language: i18n.language
        });
        return response;
      } catch (error) {
        return rejectWithValue(error.message as string);
      }
    }
  ),

  addQuestion: createAsyncThunk(
    `${STORE}/addQuestion`,
    async (props: { question: string }, { rejectWithValue, getState }) => {
      try {
        const state = getState() as RootState;
        const prematterId = selectors.session.getPrematterId()(state);
        const prematter = await api.prematter.addQuestion({
          id: prematterId,
          question: props.question,
        });
        return prematter;
      } catch (error) {
        console.error('send FAQ', error);
        return rejectWithValue(error.message as string);
      }
    }
  ),
  deleteQuestion: createAsyncThunk(
    `${STORE}/deleteQuestion`,
    async (props: { questionId: string }, { rejectWithValue, getState }) => {
      try {
        const state = getState() as RootState;
        const prematterId = selectors.session.getPrematterId()(state);
        await api.prematter.deleteQuestion({
          id: prematterId,
          questionId: props.questionId,
        });
        return props;
      } catch (error) {
        console.error('send FAQ', error);
        return rejectWithValue(error.message as string);
      }
    }
  ),
  getForm: createAsyncThunk(
    `${STORE}/getForm`,
    async (_, { rejectWithValue, getState }) => {
      try {
        const state = getState() as RootState;
        const prematterId = selectors.session.getPrematterId()(state);
        const response = await api.prematter.getForm({
          id: prematterId,
        });
        return response;
      } catch (error) {
        console.error('get form', error);
        toast.error(i18n.t('toast.general-error'));
        return rejectWithValue(error.message as string);
      }
    }
  ),
  sendForm: createAsyncThunk(
    `${STORE}/sendForm`,
    async (
      props: { form: IntroductoryForm; generate?: boolean },
      { rejectWithValue, getState }
    ) => {
      try {
        const state = getState() as RootState;
        const prematterId = selectors.session.getPrematterId()(state);
        const response = await api.prematter.submitForm({
          id: prematterId,
          form: props.form,
          generate: props.generate,
        });
        return response;
      } catch (error) {
        console.error('submit form', error);
        toast.error(i18n.t('toast.send-case-form-error'));
        return rejectWithValue(error.message as string);
      }
    }
  ),
  updateExtendedForm: createAsyncThunk(
    `${STORE}/updateExtendedForm`,
    async (
      props: { id: string; extendedForm: ExtendedForm; generate?: boolean },
      { rejectWithValue }
    ) => {
      try {
        const response = await api.prematter.updateExtendedForm({
          id: props.id,
          extendedForm: props.extendedForm,
          generate: props.generate,
        });
        return response;
      } catch (error) {
        console.error('update extended form', error);
        return rejectWithValue(error.message as string);
      }
    }
  ),
  process: createAsyncThunk(
    `${STORE}/process`,
    async (_, { rejectWithValue, getState }) => {
      try {
        const state = getState() as RootState;
        const prematterId = selectors.session.getPrematterId()(state);
        await api.prematter.process({
          id: prematterId,
        });
      } catch (error) {
        console.error('process', error);
        return rejectWithValue(error.message as string);
      }
    }
  ),
  recaptcha: createAsyncThunk(
    `${STORE}/recaptcha`,
    async (args: { token: string }, { rejectWithValue }) => {
      try {
        const response = await api.session.recaptcha(args);
        return response;
      } catch (error) {
        console.error('recaptcha', error);
        return rejectWithValue(error.message as string);
      }
    }
  ),
};

export default sessionThunk;
