import { getI18n } from 'react-i18next';

export const formatDate = (dateOrTimestamp: Date | number, locale?: string) => {
  const userLocale = locale || getI18n().language || navigator.language; // Use the user's locale or default to browser's language setting
  const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone; // Get user's local timezone

  return new Intl.DateTimeFormat(userLocale, {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    timeZone: userTimeZone,
  }).format(dateOrTimestamp);
};

export const formatTime = (dateOrTimestamp: Date | number, locale?: string) => {
  const userLocale = locale || getI18n().language || navigator.language; // Use the user's locale or default to browser's language setting
  const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone; // Get user's local timezone
  return new Intl.DateTimeFormat(userLocale, {
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    timeZone: userTimeZone,
  }).format(dateOrTimestamp);
};

export const formatDateTime = (
  dateOrTimestamp: Date | number,
  locale?: string
) => {
  const userLocale = locale || getI18n().language || navigator.language; // Use the user's locale or default to browser's language setting
  const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone; // Get user's local timezone
  return new Intl.DateTimeFormat(userLocale, {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    timeZone: userTimeZone,
  }).format(dateOrTimestamp);
};

export const formatMediumDateTime = (
  dateOrTimestamp: Date | number,
  locale?: string
) => {
  const userLocale = locale || getI18n().language || navigator.language; // Use the user's locale or default to browser's language setting
  const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone; // Get user's local timezone
  return new Intl.DateTimeFormat(userLocale, {
    year: 'numeric',
    month: 'short',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    timeZone: userTimeZone,
  }).format(dateOrTimestamp);
};