import { useTranslation } from 'react-i18next';
import ChatIcon from '../../../assets/icons/chat.svg?react';
import ShieldTickIcon from '../../../assets/icons/shield-tick.svg?react';
import RandomIcon from '../../../assets/icons/random.svg?react';
import step1Image from '../../../assets/home/home-feature-1.jpg';
import step2Image from '../../../assets/home/home-feature-2.jpg';
import step3Image from '../../../assets/home/home-feature-3.jpg';

import { FC, SVGProps, useEffect, useMemo } from 'react';

export interface Steps {
  index: number;
  name: string;
  Icon: FC<SVGProps<SVGSVGElement>>;
  title: string;
  description: string;
  image: string;
  video: string;
}

export const useCarouselSteps = (): Steps[] => {
  const { t, i18n } = useTranslation();

  const language = i18n.language;

  const memo = useMemo(() =>  [
    {
      index: 0,
      name: t('home.how-it-works.step1.subtitle'),
      Icon: ChatIcon,
      title: t('home.how-it-works.step1.title'),
      description: t('home.how-it-works.step1.description'),
      image: step1Image,
      video: `/home/videos/${language}/Video1-compressed.mp4`,
    },
    {
      index: 1,
      name: t('home.how-it-works.step2.subtitle'),
      Icon: ShieldTickIcon,
      title: t('home.how-it-works.step2.title'),
      description: t('home.how-it-works.step2.description'),
      image: step2Image,
      video: `/home/videos/${language}/Video2-compressed.mp4`,
    },
    {
      index: 2,
      name: t('home.how-it-works.step3.subtitle'),
      Icon: RandomIcon,
      title: t('home.how-it-works.step3.title'),
      description: t('home.how-it-works.step3.description'),
      image: step3Image,
      video: `/home/videos/${language}/Video3-compressed.mp4`,
    },
  ], [t, language]);

  return memo;
};
