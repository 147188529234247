import { PayloadAction, createListenerMiddleware } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { actions } from '../slices';
import selectors from '../selectors';
import {
  Message,
  WebSocketMessage,
  WebSocketMessageType,
} from '@law-connect/types';
import { security } from '../api/security';
import { t } from 'i18next';

const MAX_ERROR_COUNT = 10;
const SERVER_DELAY_TIME = 15000;
let ws: WebSocket = null;
let listeningTimer: NodeJS.Timeout = null;
let serverResponseTimer: NodeJS.Timeout = null;
let pingTimer: NodeJS.Timeout = null;
let botDetected = false;

interface InitWebsocketArgs {
  url: string;
  token?: string;
  onError?: (event: ErrorEvent) => void;
  onOpen?: () => void;
  onClose?: () => void;
  keepAlive?: boolean;
  onMessage: (event: MessageEvent) => void;
}
const initWebsocket = (args: InitWebsocketArgs) => {
  const { url, token, keepAlive, onError, onOpen, onClose, onMessage } = args;
  if (ws) {
    ws.close();
  }

  ws = new WebSocket(url, token);

  ws.onerror = (event: ErrorEvent) => {
    pingTimer && clearInterval(pingTimer);

    onError?.(event);

    // Re-connect on an error
    initWebsocket(args);
  };

  ws.onclose = () => {
    pingTimer && clearInterval(pingTimer);
    onClose?.();
  };

  ws.onopen = () => {
    if (keepAlive) {
      const sendPing = () => {
        if (ws.readyState === ws.OPEN) {
          const ping: WebSocketMessage = {
            from: 'system',
            message: '',
            type: WebSocketMessageType.Ping,
          };
          ws.send(JSON.stringify(ping));
        }
      };
      pingTimer = setInterval(sendPing, 30000);
      sendPing();
    }

    onOpen?.();
  };

  ws.onmessage = onMessage;
};

const listenerMiddleware = createListenerMiddleware();

// listening to send message to websocket
listenerMiddleware.startListening({
  actionCreator: actions.websocket.sendMessage,
  effect: async (action, listenerApi) => {
    const dispatchAction = () => {
      action.payload.errorCount = undefined;
      ws.send(JSON.stringify(action.payload));
      listenerApi.dispatch(
        actions.session.addMessage(
          action.payload as unknown as WebSocketMessage
        )
      );
      if (!serverResponseTimer) {
        serverResponseTimer = setTimeout(() => {
          listenerApi.dispatch(
            actions.websocket.sendMessageError(
              'Failed to get a response from server'
            )
          );
          serverResponseTimer = null;
        }, SERVER_DELAY_TIME);
      }
    };
    const state = listenerApi.getState() as RootState;
    const isWsConnected = selectors.websocket.isSetUp()(state);
    // Run whatever additional side-effect-y logic you want here
    if (!ws || ws.readyState !== ws.OPEN || !isWsConnected) {
      if (
        (!action.payload.errorCount ||
          action.payload.errorCount < MAX_ERROR_COUNT) &&
        !botDetected
      ) {
        if (listeningTimer) {
          clearTimeout(listeningTimer);
        }
        listeningTimer = setTimeout(() => {
          listenerApi.dispatch(
            actions.websocket.sendMessage({
              ...action.payload,
              errorCount: action.payload.errorCount
                ? action.payload.errorCount + 1
                : 1,
            })
          );
          listeningTimer = null;
        }, 1000 * (action.payload.errorCount ?? 1));
      } else {
        listenerApi.dispatch(
          actions.websocket.sendMessageError('Failed to send message')
        );
      }
    } else {
      dispatchAction();
    }
  },
});

// we want to delete the session and disconnect the websocket
listenerMiddleware.startListening({
  actionCreator: actions.websocket.deleteSession,
  effect: async (action, listenerApi) => {
    if (ws && ws.readyState === ws.OPEN) {
      ws.onclose = () => {
        pingTimer && clearInterval(pingTimer);
        listenerApi.dispatch(actions.websocket.deleteSessionSuccess());
      };
      ws.close();
    } else {
      listenerApi.dispatch(actions.websocket.deleteSessionSuccess());
    }
  },
});

export default listenerMiddleware;
