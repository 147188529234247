import React, {
  CSSProperties,
  FC,
  SVGProps,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useNavigate } from 'react-router-dom';
import style from './style.module.less';
import { useScreenSize, WidthType } from '../../../hooks/use-is-mobile';
import { MobileStepsCarousel } from './mobile';
import { CarouselHeader } from './header';
import { PercentageSpinner } from '../../../components/percentage-spinner';
import { useCarouselSteps } from './use-carousel-steps';
import { TRANSITION_DURATION, VideoWrapper } from './video-wrapper';
import { GetStartedComponent } from './get-started';
import { CHAT_EMAIL } from '../../../constants';

export const StepsCarouselComponent: FC = () => {
  const screenType = useScreenSize();
  const [activeStep, setActiveStep] = useState(0);
  const [progressRate, setProgressRate] = useState(0);
  const [isTransitioning, setIsTransitioning] = useState(false);

  const carouselSteps = useCarouselSteps();
  const navigate = useNavigate();

  const clearProgressRate = useCallback(() => {
    setProgressRate(0);
  }, []);
  const goNextStep = useCallback(
    (nextStep?: number) => {
      setIsTransitioning(true);
      setTimeout(() => {
        clearProgressRate();
        setActiveStep(nextStep ?? (activeStep + 1) % carouselSteps.length);
        setIsTransitioning(false);
      }, TRANSITION_DURATION);
    },
    [activeStep, carouselSteps.length, clearProgressRate]
  );

  const incrementProgressRate = useCallback((amount: number) => {
    setProgressRate((p) => p + amount);
  }, []);

  const emailUrl = useMemo(() => {
    return `mailto:${CHAT_EMAIL}?subject=Legal question`;
  }, []);

  useEffect(() => {
    const button = document.getElementById('email-button');
    if (button) {
      button.classList.add(style.emailLink);
      button.onclick = () => {
        window.location.href = emailUrl;
      };
    }
  }, [emailUrl]);

  return (
    <div>
      {screenType === WidthType.Mobile ? (
        <MobileStepsCarousel
          activeIndex={activeStep}
          progressRate={progressRate}
          changeIndex={goNextStep}
          GetStartedComponent={<GetStartedComponent />}
          carouselSteps={carouselSteps}
          clearProgressRate={clearProgressRate}
          incrementProgressRate={incrementProgressRate}
        />
      ) : (
        <>
          <CarouselHeader
            carouselSteps={carouselSteps}
            activeIndex={activeStep}
            changeIndex={goNextStep}
          />
          <div className={style.container}>
            <VideoWrapper
              carouselSteps={carouselSteps}
              activeStep={activeStep}
              goNextStep={goNextStep}
              clearProgressRate={clearProgressRate}
              incrementProgressRate={incrementProgressRate}
              isTransitioning={isTransitioning}
              setIsTransitioning={setIsTransitioning}
            />
            <div className={style.cardsContainer}>
              {carouselSteps.map((step, index) => {
                const isActive = activeStep === index;
                return (
                  <div
                    key={index}
                    className={`${style.card} ${
                      isActive ? style.isActive : ''
                    }`}
                    onClick={() => goNextStep(index)}
                  >
                    <div className={style.cardName}>{step.name}</div>
                    <step.Icon className={style.cardIcon} />
                    <div className={style.cardTitle}>{step.title}</div>
                    <div
                      className={style.cardDescription}
                      dangerouslySetInnerHTML={{ __html: step.description }}
                    />
                    <div className={style.loadingSpinner}>
                      <PercentageSpinner
                        percentage={progressRate}
                        size={32}
                        noText={true}
                        strokeWidth={3}
                        className={style.percentageSpinner}
                      />
                    </div>
                  </div>
                );
              })}
            </div>
            <GetStartedComponent />
          </div>
        </>
      )}
    </div>
  );
};
