/* eslint-disable @stylistic/js/max-len */
import { LibraryCategory } from '../../../../types/library';
import cases from './cases.json';
import bubbleImage from '../images/family-law-bubble.png';
import familyImage from '../images/family.jpg';
import familyImageFull from '../images/family-full.jpg';
import Icon from '../images/family-law.svg?react';

const TITLE = 'Family law';

const familyLaw: LibraryCategory = {
  name: TITLE,
  title: 'Introduction to Family law in Australia',
  image: familyImage,
  previewImage: familyImageFull,
  shortDescription:
    'Navigating family law in Australia can be complex and challenging.',
  description:
    'Family law is the area of law that deals with family matters and domestic relations, including marriage, civil unions, and domestic partnership. ',
  cases: cases.filter((c) => c.category.toLocaleLowerCase() === TITLE.toLocaleLowerCase()),
  Icon,
  bubbleImage,
  bodyText: [
    {
      header: 'Family Law Act 1975',
      body: 'The foundational legislation governing family law in Australia, covering marriage, divorce, property settlements, and arrangements for children post-separation.'
    },
    {
      header: 'Divorce and separation',
      body: 'Family law outlines the processes for legally ending a marriage or partnership, including the requirements for divorce and provisions for property division.'
    },
    {
      header: 'Child custody and parenting arrangements',
      body: 'In cases of separation, the law prioritizes the best interests of the child, establishing guidelines for parenting arrangements, custody, and visitation rights.'
    },
    {
      header: 'Property and financial settlements',
      body: 'Family law includes principles for dividing assets and liabilities fairly between partners, considering factors like financial contributions and future needs.'
    },
    {
      header: 'Family violence and protection orders',
      body: 'Family law protects individuals from domestic violence through intervention orders, which place restrictions on behavior to ensure safety for victims and children.'
    }
  ],
};

export default familyLaw;
