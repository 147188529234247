/* eslint-disable @stylistic/js/max-len */
import LeftMaleLawyer1 from '../../assets/lawyers/left-man-1.png';
import LeftMaleLawyer2 from '../../assets/lawyers/left-man-2.png';
import LeftMaleLawyer3 from '../../assets/lawyers/left-man-3.png';
import LeftFemaleLawyer1 from '../../assets/lawyers/left-woman-1.png';
import LeftFemaleLawyer2 from '../../assets/lawyers/left-woman-2.png';
import LeftFemaleLawyer3 from '../../assets/lawyers/left-woman-3.png';
import RightMaleLawyer1 from '../../assets/lawyers/right-man-1.png';
import RightMaleLawyer2 from '../../assets/lawyers/right-man-2.png';
import RightMaleLawyer3 from '../../assets/lawyers/right-man-3.png';
import RightFemaleLawyer1 from '../../assets/lawyers/right-woman-1.png';
import RightFemaleLawyer2 from '../../assets/lawyers/right-woman-2.png';
import RightFemaleLawyer3 from '../../assets/lawyers/right-woman-3.png';

import lawyer1Full from '../../assets/lawyers/lawyer-1-full.jpg';
import lawyer2Full from '../../assets/lawyers/lawyer-2-full.jpg';
import lawyer3Full from '../../assets/lawyers/lawyer-3-full.jpg';
import lawyer4Full from '../../assets/lawyers/lawyer-4-full.jpg';
import lawyer5Full from '../../assets/lawyers/lawyer-5-full.jpg';
import lawyer6Full from '../../assets/lawyers/lawyer-6-full.jpg';

import homeIconLawyer1 from '../../assets/home/library-logo-man1.svg';
import homeIconLawyer2 from '../../assets/home/library-logo-man2.svg';
import homeIconLawyer3 from '../../assets/home/library-logo-man3.svg';
import homeIconLawyer4 from '../../assets/home/library-logo-woman1.svg';
import homeIconLawyer5 from '../../assets/home/library-logo-woman2.svg';
import homeIconLawyer6 from '../../assets/home/library-logo-woman3.svg';

export interface HomeLawyerData {
  icon: string;
  backgroundColor: string;
  primaryColor: string;
}

const HomeLawyer1: HomeLawyerData = {
  icon: homeIconLawyer1,
  backgroundColor:
    'rgba(173, 181, 185, 0.20)',
  primaryColor: 'rgba(173, 181, 185, 0.50)',
};

const HomeLawyer2: HomeLawyerData = {
  icon: homeIconLawyer2,
  backgroundColor:
    'rgba(213, 166, 115, 0.20)',
  primaryColor: 'rgba(213, 166, 115, 0.50)',
};

const HomeLawyer3: HomeLawyerData = {
  icon: homeIconLawyer3,
  backgroundColor:
    'rgba(7, 42, 93, 0.20)',
  primaryColor: 'rgba(7, 42, 93, 0.50)',
};

const HomeLawyer4: HomeLawyerData = {
  icon: homeIconLawyer4,
  backgroundColor:
    'rgba(168, 36, 48, 0.20)',
  primaryColor: 'rgba(168, 36, 48, 0.50)',
};

const HomeLawyer5: HomeLawyerData = {
  icon: homeIconLawyer5,
  backgroundColor:
    'rgba(132, 155, 112, 0.20)',
  primaryColor: 'rgba(132, 155, 112, 0.50)',
};

const HomeLawyer6: HomeLawyerData = {
  icon: homeIconLawyer6,
  backgroundColor:
    'rgba(201, 142, 173, 0.20)',
  primaryColor: 'rgba(201, 142, 173, 0.50)',
};

export interface LawyerImage {
  backgroundColor: string;
  female?: boolean;
  fullLawyerImage: string;
  homeColours: HomeLawyerData;
  leftImage: string;
  rightImage: string;
}

export const lawyerImages: LawyerImage[] = [
  {
    leftImage: LeftMaleLawyer1,
    rightImage: RightMaleLawyer1,
    backgroundColor: 'linear-gradient(209deg, #64808D 6.75%, #99B2B7 110.94%)',
    fullLawyerImage: lawyer1Full,
    homeColours: HomeLawyer1,
  },
  {
    leftImage: LeftMaleLawyer2,
    rightImage: RightMaleLawyer2,
    backgroundColor: 'linear-gradient(209deg, #C19871 6.75%, #DAAB7A 110.94%)',
    fullLawyerImage: lawyer2Full,
    homeColours: HomeLawyer2,
  },
  {
    leftImage: LeftMaleLawyer3,
    rightImage: RightMaleLawyer3,
    backgroundColor: 'linear-gradient(209deg, #020E20 6.75%, #3E4C58 110.94%)',
    fullLawyerImage: lawyer3Full,
    homeColours: HomeLawyer3,
  },
  // female lawyers
  {
    leftImage: LeftFemaleLawyer1,
    rightImage: RightFemaleLawyer1,
    backgroundColor: 'linear-gradient(209deg, #4F2023 6.75%, #782F2A 110.94%)',
    female: true,
    fullLawyerImage: lawyer4Full,
    homeColours: HomeLawyer4,
  },
  {
    leftImage: LeftFemaleLawyer2,
    rightImage: RightFemaleLawyer2,
    backgroundColor: 'linear-gradient(209deg, #426049 6.75%, #728866 110.94%)',
    female: true,
    fullLawyerImage: lawyer5Full,
    homeColours: HomeLawyer5,
  },
  {
    leftImage: LeftFemaleLawyer3,
    rightImage: RightFemaleLawyer3,
    backgroundColor: 'linear-gradient(209deg, #AE7B7A 6.75%, #EBB9AD 110.94%)',
    female: true,
    fullLawyerImage: lawyer6Full,
    homeColours: HomeLawyer6,
  },
];
