import React, { FC, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import style from './style.module.less';
import { ContentTitle } from '../content-title';
import { MessageRowComponent } from './row';
import { useAppSelector } from '../../redux/hooks';
import selectors from '../../redux/selectors';
import { LawyerConnection, Prematter } from '@law-connect/types';
import NoMessagesIcon from '../../assets/icons/no-messages.svg?react';
import LeftArrowIcon from '../../assets/icons/arrow-left.svg?react';
import RightArrowIcon from '../../assets/icons/arrow-right.svg?react';

const PAGE_SIZE = 5;

export const AccountMessages: FC = () => {
  const { t } = useTranslation();

  const connections = useAppSelector(
    selectors.lawyerConnection.getConnections()
  );
  const prematters = useAppSelector(selectors.prematter.getAll());
  const filteredConnections = useMemo(
    () =>
      [...(connections ?? [])]
        .filter((message) => {
          const prematter = prematters.find(
            (prematter) => prematter?.id === message?.prematterId
          );
          return prematter && message;
        })
        .sort((a, b) => {
          if (!a.readAt && b.readAt) {
            return -1;
          } else if (a.readAt && !b.readAt) {
            return 1;
          } else {
            return a.updatedAt > b.updatedAt ? -1 : 1;
          }
        }),
    [connections, prematters]
  );

  return filteredConnections?.length ? (
    <div className={style.container}>
      <ContentTitle
        title={t('connect.messages')}
        className={style.contentTitle}
      />
      <div className={style.rowContainer}>
        <LawyerMessages
          connections={filteredConnections}
          prematters={prematters}
          usePrematterNameDescription={true}
        />
      </div>
    </div>
  ) : null;
};

interface Props {
  connections: LawyerConnection[];
  prematters: Prematter[];
  usePrematterNameDescription?: boolean;
}

export const LawyerMessages: FC<Props> = (props) => {
  const { connections, prematters, usePrematterNameDescription } = props;
  const connectionLists: LawyerConnection[][] = connections.reduce(
    (acc, message) => {
      if (acc[acc.length - 1].length >= PAGE_SIZE) {
        acc.push([message]);
      } else {
        acc[acc.length - 1].push(message);
      }
      return acc;
    },
    [[]]
  );
  const [pageIndex, setPageIndex] = React.useState(0);

  useEffect(() => {
    // if connections length changes (delete connection) we want to reset the page index
    if (pageIndex > connectionLists.length - 1) {
      setPageIndex(connectionLists.length - 1);
    }
  }, [connectionLists, pageIndex]);

  if (!connections.length) {
    return (
      <div className={style.noMessages}>
        <NoMessagesIcon />
        <div className={style.noMessagesTitle}>No messages</div>
      </div>
    );
  }

  return (
    <div className={style.messageContainer}>
      <div className={style.pageContainer}>
        {connectionLists[pageIndex]?.map((message) => {
          const prematter = prematters.find(
            (prematter) => prematter?.id === message?.prematterId
          );
          return (
            <MessageRowComponent
              key={message.id}
              id={message.id}
              usePrematterNameDescription={usePrematterNameDescription}
              prematterName={prematter?.name}
              firmName={message.lawyer.firm?.name}
              name={`${message.lawyer.firstName} ${
                message.lawyer.lastName
              }`.trim()}
              timestamp={message.updatedAt ?? message.createdAt}
              image={message.lawyer.image}
              isNew={message.readAt == null}
              isEnded={!!message.endedAt}
            />
          );
        })}
      </div>
      {/* only show if we have more than 1 array of sets */}
      {connectionLists?.length > 1 ? (
        <div className={style.pageNavigation}>
          <button
            className={style.pageButton}
            onClick={() => setPageIndex((i) => i - 1)}
            disabled={pageIndex === 0}
          >
            <LeftArrowIcon className={style.arrowIcon} />
          </button>
          <div className={style.pageNumbersContainer}>
            {Array.from({ length: connectionLists.length }, (_, i) => i).map(
              (i) => (
                <div
                  key={i}
                  className={`${style.pageNumbers} ${
                    i === pageIndex ? style.active : ''
                  }`}
                  onClick={() => setPageIndex(i)}
                >
                  {i + 1}
                </div>
              )
            )}
          </div>
          <button
            className={style.pageButton}
            onClick={() => setPageIndex((i) => i + 1)}
            disabled={pageIndex === connectionLists.length - 1}
          >
            <RightArrowIcon className={style.arrowIcon} />
          </button>
        </div>
      ) : null}
    </div>
  );
};
