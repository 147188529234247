import React from 'react';
import { Header } from '../../components/header';
import { Footer } from '../../components/footer';
import {
  NarrowWrapper,
  WrapperOffsetSide,
} from '../../components/narrow-wrapper';
import { Link } from 'react-router-dom';
import style from './style.module.less';
import { PageTitleHero } from '../../components/page-title-hero';
import { ContentParagraphs } from '../../components/content-paragraphs';
import { useTranslation } from 'react-i18next';
import { CONTACT_US_LINK, INFO_LINK } from '../../constants';
import { Helmet } from 'react-helmet';
import { ContentWrapper } from '../../components/content-wrapper';

interface Props {}
export const Terms: React.FC<Props> = () => {
  const { t } = useTranslation();
  return (
    <>
      <Helmet>
        <title>{t('pages.terms')}</title>
      </Helmet>
      <Header />
      <PageTitleHero title={t('terms.title')} />
      <ContentWrapper
        className={style.contentBackground}
        contentClassName={style.contentWrapper}
      >
        <div className={style.content}>
          <div className={style.section}>
            <ContentParagraphs className={style.paragraph}>
              <p>{t('terms.description.0')}</p>
              <p>{t('terms.description.1')}</p>
            </ContentParagraphs>
          </div>

          <div className={style.section}>
            <h2>1. {t('terms.0.title')}</h2>
            <ContentParagraphs className={style.paragraph}>
              <p>{t('terms.0.body.0')}</p>
              <p>{t('terms.0.body.1')}</p>
              <p>{t('terms.0.body.2')}</p>
            </ContentParagraphs>

            <p>&nbsp;</p>
            <h2>2. {t('terms.1.title')}</h2>
            <ContentParagraphs className={style.paragraph}>
              <p>{t('terms.1.body.0')}</p>
              <p>{t('terms.1.body.1')}</p>
              <p>{t('terms.1.body.2')}</p>
              <p>{t('terms.1.body.3')}</p>
              <p>{t('terms.1.body.4')}</p>
              <p>{t('terms.1.body.5')}</p>
            </ContentParagraphs>

            <p>&nbsp;</p>
            <h2>3. {t('terms.2.title')}</h2>
            <ContentParagraphs className={style.paragraph}>
              <p>{t('terms.2.body.0')}</p>
            </ContentParagraphs>

            <p>&nbsp;</p>
            <h2>4. {t('terms.3.title')}</h2>
            <ContentParagraphs className={style.paragraph}>
              <p>{t('terms.3.body.0')}</p>
              <p>{t('terms.3.body.1')}</p>
              <p>{t('terms.3.body.2')}</p>
              <p>{t('terms.3.body.3')}</p>
            </ContentParagraphs>

            <p>&nbsp;</p>
            <h2>5. {t('terms.4.title')}</h2>
            <ContentParagraphs className={style.paragraph}>
              <p>{t('terms.4.body.0')}</p>
            </ContentParagraphs>

            <p>&nbsp;</p>
            <h2>6. {t('terms.5.title')}</h2>
            <ContentParagraphs className={style.paragraph}>
              <p>{t('terms.5.body.0')}</p>
              <p>
                {t('terms.5.body.1')}
                <ul>
                  <li>{t('terms.5.body.2')}</li>
                  <li>{t('terms.5.body.3')}</li>
                  <li>{t('terms.5.body.4')}</li>
                  <li>{t('terms.5.body.5')}</li>
                  <li>{t('terms.5.body.6')}</li>
                  <li>{t('terms.5.body.7')}</li>
                  <li>{t('terms.5.body.8')}</li>
                  <li>{t('terms.5.body.9')}</li>
                  <li>{t('terms.5.body.10')}</li>
                </ul>
              </p>
              <p>{t('terms.5.body.11')}</p>
              <p>{t('terms.5.body.12')}</p>
              <p>{t('terms.5.body.13')}</p>
              <p>{t('terms.5.body.14')}</p>
            </ContentParagraphs>

            <p>&nbsp;</p>
            <h2>7. {t('terms.6.title')}</h2>
            <ContentParagraphs className={style.paragraph}>
              <p>{t('terms.6.body.0')}</p>
              <p>{t('terms.6.body.1')}</p>
            </ContentParagraphs>

            <p>&nbsp;</p>
            <h2>8. {t('terms.7.title')}</h2>
            <ContentParagraphs className={style.paragraph}>
              <p>{t('terms.7.body.0')}</p>
              <p>{t('terms.7.body.1')}</p>
              <p>{t('terms.7.body.2')}</p>
              <p>{t('terms.7.body.3')}</p>
              <p>{t('terms.7.body.4')}</p>
              <p>{t('terms.7.body.5')}</p>
              <p>{t('terms.7.body.6')}</p>
              <p>{t('terms.7.body.7')}</p>
              <p>{t('terms.7.body.8')}</p>
            </ContentParagraphs>

            <p>&nbsp;</p>
            <h2>9. {t('terms.8.title')}</h2>
            <ContentParagraphs className={style.paragraph}>
              <p>{t('terms.8.body.0')}</p>
              <p>{t('terms.8.body.1')}</p>
              <p>{t('terms.8.body.2')}</p>
            </ContentParagraphs>

            <p>&nbsp;</p>
            <h2>10. {t('terms.9.title')}</h2>
            <ContentParagraphs className={style.paragraph}>
              <p>{t('terms.9.body.0')}</p>
              <p>{t('terms.9.body.1')}</p>
            </ContentParagraphs>

            <p>&nbsp;</p>
            <h2>11. {t('terms.10.title')}</h2>
            <ContentParagraphs className={style.paragraph}>
              <p>{t('terms.10.body.0')}</p>
              <p>{t('terms.10.body.1')}</p>
            </ContentParagraphs>
          </div>

          <div className={style.section}>
            <h2>{t('terms.contact.title')}</h2>
            <ContentParagraphs className={style.paragraph}>
              <p
                className={style.contact}
                dangerouslySetInnerHTML={{
                  __html: t('terms.contact.body').replace(
                    'CONTACT_LINK',
                    CONTACT_US_LINK
                  ),
                }}
              />
            </ContentParagraphs>
          </div>
        </div>
      </ContentWrapper>
      <Footer />
    </>
  );
};
