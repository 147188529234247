/* eslint-disable @stylistic/js/max-len */
import { LibraryCategory } from '../../../../types/library';
import cases from './cases.json';
import libraryEmploymentImage from '../images/employment.jpg';
import libraryEmploymentImageFull from '../images/employment-full.jpg';

import Icon from '../images/employment-law.svg?react';
import bubbleImage from '../images/employment-law-bubble.png';

const TITLE = 'Employment and labor law';

const employmentLaw: LibraryCategory = {
  name: TITLE,
  title: 'Introduction to Employment law in Australia',
  image: libraryEmploymentImage,
  previewImage: libraryEmploymentImageFull,
  shortDescription: 'Fair treatment for both employers and employees.',
  description:
    'Employment law in Australia, primarily governed by the Fair Work Act 2009, sets out the rights and obligations of both employees and employers, ensuring fair treatment, workplace safety, and compliance with legal standards.',
  cases: cases.filter((c) => c.category.toLocaleLowerCase() === TITLE.toLocaleLowerCase()),
  Icon,
  bubbleImage,
  bodyText: [
    {
      header: 'Fair Work Act 2009',
      body: 'This central legislation governs employment law, establishing the rights and obligations of employees and employers, setting minimum workplace standards, and outlining processes for resolving disputes.',
    },
    {
      header: 'National Employment Standards (NES)',
      body: 'The NES outlines 11 minimum employment entitlements for employees, including maximum weekly hours, parental leave, annual leave, and personal/carer\'s leave, ensuring a baseline level of protection.',
    },
    {
      header: 'Modern awards',
      body: 'These legal documents specify minimum pay rates and working conditions for various industries or occupations, providing additional protections beyond the NES.'
    },
    {
      header: 'Termination and unfair dismissal',
      body: 'Employers must have a valid reason for terminating an employee and follow proper procedures. Employees can claim unfair dismissal if they believe they were unjustly dismissed.'
    }, 
    {
      header: 'Workplace Health and Safety (WHS)',
      body: 'Employers are obligated under the Work Health and Safety Act to provide a safe work environment, including hazard identification, employee training, and injury prevention measures.'
    }
  ],
};

export default employmentLaw;
